import { fromJS } from "immutable";
import { AnyAction } from "redux";

import { RESET_STORE } from "../StateManagement/constants";

import { CLOSE_SHELF, OPEN_SHELF } from "./constants";

const initialState = fromJS({}) as any;

const reducer = (state = initialState, { type, id, props }: AnyAction) => {
  switch (type) {
    case OPEN_SHELF: {
      return state.setIn([id, "open"], true).setIn([id, "props"], props);
    }

    case CLOSE_SHELF: {
      return state.setIn([id, "open"], false);
    }
    case RESET_STORE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
