import { stopSubmit } from "redux-form";
import { put, takeLatest } from "redux-saga/effects";
import _ from "underscore";

import { toast } from "components/Flows/Toast/core";

function* errorHandler({ error, form, customMessage }: any) {
  const errors = error;
  if (!errors) {
    return;
  }
  const haveGlobalErrors =
    Array.isArray(errors) && errors.some((el: any) => el._error);

  const validationErrors: string[] = [];
  if (Array.isArray(errors)) {
    errors.forEach((er): any => {
      const keys = Object.keys(er).filter((k: string) => k !== "code");
      validationErrors.push(`${er[keys[0]]}`);
    });
  }

  if (haveGlobalErrors || validationErrors?.length) {
    yield put(
      stopSubmit(
        form,
        haveGlobalErrors
          ? _.assign({}, ...errors)
          : { _error: validationErrors }
      )
    );
  }
  if (customMessage) {
    toast.success({ title: customMessage }, { autoClose: 5000, type: "error" });
  }
}

function* Saga() {
  yield takeLatest(
    (action: any) => action.type.includes("_FAILURE"),
    errorHandler
  );
}

export default Saga;
