import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//@ts-ignore
import translationEN from "./app/Localization/locales/en/translationEN.json";
//@ts-ignore
import translationES from "./app/Localization/locales/es/translationES.json";

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

i18n.use(initReactI18next).init({
  debug: false, 
  saveMissing: false, 
  missingKeyHandler: false, 
  resources,
  fallbackLng: "en",
  supportedLngs: ["en", "es"],
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
