import React from "react";

import classes from "../../assets/scss/style.module.scss";

import FileItem from "./FileItem";

export interface FileListProps {
  files: File[];
  onDelete: (file: File) => void;
  id: string;
}

export const FileList = React.memo(
  (props: React.PropsWithChildren<FileListProps>) => (
    <div className={classes.UploadFileListContainer}>
      {props?.files.map((file: any, y) => {
        return (
          <FileItem
            onDelete={props.onDelete}
            file={file}
            hash={file.hash}
            id={props?.id}
            key={y}
            name={file?.name}
          />
        );
      })}
    </div>
  )
);

export default FileList;
