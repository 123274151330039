import createAction from "utils/createAction";

import * as ACTION_TYPE from "./constants";

export const getCasesRequest = createAction(ACTION_TYPE.GET_CASES_REQUEST);

export const getCasesSucces = createAction(ACTION_TYPE.GET_CASES_SUCCESS);

export const getCasesFailure = createAction(ACTION_TYPE.GET_CASES_FAILURE);

export const deleteCaseRequest = createAction(ACTION_TYPE.DELETE_CASE_REQUEST);

export const deleteCaseStatusUpdate = createAction(
  ACTION_TYPE.DELETE_CASE_STATUS_UPDATE
);

export const deleteCaseSuccess = createAction(ACTION_TYPE.DELETE_CASE_SUCCESS);

export const deleteCaseFailure = createAction(ACTION_TYPE.DELETE_CASE_FAILURE);

export const analyzeRequest = createAction(ACTION_TYPE.ANALYZE_REQUEST);

export const analyzeSuccess = createAction(ACTION_TYPE.ANALYZE_SUCCESS);

export const analyzeFailure = createAction(ACTION_TYPE.ANALYZE_FAILURE);

export const retryAnalysisRequest = createAction(
  ACTION_TYPE.RETRY_ANALYSIS_REQUEST
);

export const retryAnalysisStatusUpdate = createAction(
  ACTION_TYPE.RETRY_ANALYSIS_STATUS_UPDATE
);

export const retryAnalysisSuccess = createAction(
  ACTION_TYPE.RETRY_ANALYSIS_SUCCESS
);

export const retryAnalysisFailure = createAction(
  ACTION_TYPE.RETRY_ANALYSIS_FAILURE
);

export const generateAssetRequest = createAction(
  ACTION_TYPE.GENERATE_ASSET_REQUEST
);

export const generateAssetStatusUpdate = createAction(
  ACTION_TYPE.GENERATE_ASSET_STATUS_UPDATE
);

export const generateAssetSuccess = createAction(
  ACTION_TYPE.GENERATE_ASSET_SUCCESS
);

export const generateAssetFailure = createAction(
  ACTION_TYPE.GENERATE_ASSET_FAILURE
);
