import React from "react";
import { connect } from "react-redux";
import ReportImg from "assets/img/report.svg";
import WomanImg from "assets/img/woman-download.svg";
import classes from "assets/scss/style.module.scss";
import cn from "classnames";
import { createStructuredSelector } from "reselect";
import { uniqId } from "../../utils/initials";
import { useTranslation } from "react-i18next";

export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: () => void;
  onFilesDrop?: (files: File[]) => void;
  className?: string | undefined;
  // upload: any;
  surveyId?: string; // todo remove ?
  caseId?: string; // todo remove ?
  setSpecificId?: any;
  typeUploader?: string;
}

const typeUploaderImage = (type: string) => {
  switch (type) {
    case "mri":
      return <img src={WomanImg} alt="WomanImg" />;
    case "report":
      return <img src={ReportImg} alt="ReportImg" />;
    default:
      return <img src={WomanImg} alt="WomanImg" />;
  }
};

const typeUploaderText = (type: string, t: any) => {
  switch (type) {
    case "mri":
      return (
        <>
          <div className={classes.dropZoneListTitle}>
            {t("components.dropzone.type-uploader.drag-n-drop-mri")}
          </div>
          <div className={classes.dropZoneListDescription}>
            {t("components.dropzone.type-uploader.file-format-mri")}
          </div>
        </>
      );
    case "report":
      return (
        <>
          <div className={classes.dropZoneListTitle}>
            {t("components.dropzone.type-uploader.drag-n-drop-report")}
          </div>
          <div className={classes.dropZoneListDescription}>
            {t("components.dropzone.type-uploader.file-format-report")}
          </div>
        </>
      );
    default:
      return (
        <>
          <div className={classes.dropZoneListTitle}>
            {t("components.dropzone.type-uploader.drag-n-drop")}
          </div>
          <div className={classes.dropZoneListDescription}>
            {t("components.dropzone.type-uploader.file-format")}
          </div>
        </>
      );
  }
};

const supportTypesBy = (type: string) => {
  switch (type) {
    case "mri":
      return "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,application/x-gtar,application/x-tgz,application/tar+gzip,application/gzip,application/x-gzip,.tgz,application/x-rar,application/x-rar-compressed,.rar";
    case "report":
      return "image/png,application/pdf";
    default:
      return "image/png,application/pdf";
  }
};

export const DropZone = React.memo(
  (props: React.PropsWithChildren<DropZoneProps>) => {
    const {
      onDragStateChange,
      onFilesDrop,
      onDrag,
      onDragIn,
      onDragOut,
      onDrop,
      className,
      setSpecificId,
      typeUploader = "mri",
      surveyId, // todo remove ?
      caseId, // todo remove ?
    } = props;
    const { t } = useTranslation();
    const support_types = supportTypesBy(typeUploader);
    const image = typeUploaderImage(typeUploader);
    const [isDragActive, setIsDragActive] = React.useState(false);

    const dropZoneRef = React.useRef<null | HTMLDivElement>(null);

    const hiddenFileInput: any = React.useRef(null);

    const handleClick = async (file = false) => {
      if (file) {
        hiddenFileInput?.current?.click();
      } else {
        const out = {};
        // @ts-ignore
        const dirHandle = await window.showDirectoryPicker();
        await handleDirectoryEntry(dirHandle, out);
      }
    };

    const handleDirectoryEntry = async (dirHandle: any, out: any) => {
      for await (const entry of dirHandle.values()) {
        if (entry.kind === "file") {
          const file = await entry.getFile();
          file.hash = uniqId();
          file.caseId = caseId; // todo remove ?
          file.surveyId = surveyId; // todo remove ?
          if (file.name !== ".DS_Store") {
            onFilesDrop?.([file]);
          }
          out[file.name] = file;
        }
        if (entry.kind === "directory") {
          const newHandle = await dirHandle.getDirectoryHandle(entry.name, {
            create: false,
          });
          const newOut = (out[entry.name] = {});
          await handleDirectoryEntry(newHandle, newOut);
        }
      }
    };

    const addSpecificHash = (files: any) => {
      return [...files].map((item) => {
        item.hash = uniqId();
        item.caseId = caseId; // todo remove ?
        item.surveyId = surveyId; // todo remove ?
        return item;
      });
    };

    const handleChange = (event: any) => {
      const id = uniqId();
      setSpecificId(id);
      const files = addSpecificHash(event.target.files);
      event.target.value = "";
      onFilesDrop?.(files);
    };

    const handleDragIn = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDragIn?.();
        const requiredTypes = support_types?.split(",");
        const filesFilter = [...event.dataTransfer.items].filter((elem) =>
          requiredTypes.includes(elem?.type)
        );
        if (filesFilter && filesFilter?.length > 0) {
          setIsDragActive(true);
        }
      },
      [onDragIn, support_types]
    );

    const handleDragOut = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDragOut?.();

        setIsDragActive(false);
      },
      [onDragOut]
    );

    const handleDrag = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        onDrag?.();
        if (!isDragActive) {
          setIsDragActive(true);
        }
      },
      [isDragActive, onDrag]
    );

    const handleDrop = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        const traverse = (entry: any, path?: any) => {
          path = path || "";
          if (entry.isFile) {
            entry.file(function (file: any) {
              file.hash = uniqId();
              file.caseId = caseId; // todo remove ?
              file.surveyId = surveyId; // todo remove ?
              if (file.name !== ".DS_Store") {
                onFilesDrop?.([file]);
              }
            });
          } else if (entry.isDirectory) {
            // Get folder contents
            const dirReader = entry.createReader();
            dirReader.readEntries(function (entries: any) {
              for (const entryP of entries) {
                traverse(entryP, `${path + entry.name}/`);
              }
            });
          }
        };

        const requiredTypes = support_types?.split(",");
        const items = [...event.dataTransfer.items].filter((elem) =>
          requiredTypes.includes(elem?.type)
        );
        for (const item of items) {
          const entry = item.webkitGetAsEntry();
          if (entry) {
            traverse(entry);
          }
        }
        setIsDragActive(false);
        onDrop?.();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
          event.dataTransfer.clearData();
        }
      },
      [onDrop, onFilesDrop, support_types]
    );

    React.useEffect(() => {
      onDragStateChange?.(isDragActive);
    }, [isDragActive]);

    React.useEffect(() => {
      const tempZoneRef = dropZoneRef?.current;
      if (tempZoneRef) {
        tempZoneRef.addEventListener("dragenter", handleDragIn);
        tempZoneRef.addEventListener("dragleave", handleDragOut);
        tempZoneRef.addEventListener("dragover", handleDrag);
        tempZoneRef.addEventListener("drop", handleDrop);
      }

      return () => {
        tempZoneRef?.removeEventListener("dragenter", handleDragIn);
        tempZoneRef?.removeEventListener("dragleave", handleDragOut);
        tempZoneRef?.removeEventListener("dragover", handleDrag);
        tempZoneRef?.removeEventListener("drop", handleDrop);
      };
    }, [support_types]);

    return (
      <div className={className} ref={dropZoneRef}>
        <div className={classes.dropZoneList}>
          <div className={classes.dropZoneListImage}>{image}</div>
          {typeUploaderText(typeUploader, t)}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            accept={support_types}
          />
          <div style={{ display: "flex" }}>
            <button
              type={"button"}
              onClick={() => handleClick(true)}
              className={cn(classes.btn, classes.btnBlue)}
            >
              {t("components.dropzone.select")}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, {
  // upload: uploadFileRequest,
})(DropZone);
