import createAction from "./utils/createAction";

import * as ACTION_TYPE from "./utils/constants";

export const setPendingFeedbackRequest = createAction(
  ACTION_TYPE.SET_PENDING_FEEDBACK
);

export const setFeedbackRequest = createAction(ACTION_TYPE.SET_FEEDBACK);

export const getFeedbackRequest = createAction(
  ACTION_TYPE.GET_FEEDBACK_REQUEST
);

export const getFeedbackSuccess = createAction(
  ACTION_TYPE.GET_FEEDBACK_SUCCESS
);

export const getFeedbackFailure = createAction(
  ACTION_TYPE.GET_FEEDBACK_FAILURE
);

export const postFeedbackRequest = createAction(
  ACTION_TYPE.POST_FEEDBACK_REQUEST
);

export const postFeedbackSuccess = createAction(
  ACTION_TYPE.POST_FEEDBACK_SUCCESS
);

export const postFeedbackFailure = createAction(
  ACTION_TYPE.POST_FEEDBACK_FAILURE
);

export const postFeedbackLoader = createAction(
  ACTION_TYPE.POST_FEEDBACK_LOADER
);
