import localizedStartRoutes from "pages/Start/routes";
import localizedViewRoutes from "pages/View/routes";
import localizedCasesRoutes from "pages/Cases/routes";
import NotFound from "components/Errors/NotFound";
import languages from "../app/Localization/languages.json";

const localizedRoutes = (lang: string) => [
  ...localizedStartRoutes(lang),
  ...localizedViewRoutes(lang),
  ...localizedCasesRoutes(lang),
  {
    path: `/${lang}/*`,
    component: NotFound,
  },
];

const rootRoutes = [
  ...languages.supportedLanguages.flatMap((lang: string) =>
    localizedRoutes(lang)
  ),
  {
    path: "*",
    component: NotFound,
  },
];

export default rootRoutes;
