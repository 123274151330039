import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios";
import _ from "underscore";

import API_GLOBAL from "api/api";
import { Services } from "constants/services";

const APP_API = process.env.REACT_APP_API;

let repeatCount = 1;
const maxRepeatCount = 3;

interface IRequest {
  service?: Services;
}

export const makeRequest = (
  options: AxiosRequestConfig,
  method?: Method,
  url?: string
): AxiosPromise => {
  return axios(options)
    .then((response) => {
      if (![200, 201, 204].includes(response.status)) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      const code = _.get(error, ["response", "status"]);

      if (code === 502 && repeatCount < maxRepeatCount) {
        repeatCount++;
        return makeRequest(options, method, url);
      }
      return Promise.reject(error?.response?.data);
    });
};

export function request({
  method,
  url: requestUrl,
  data,
  params,
  headers,
  onUploadProgress,
}: AxiosRequestConfig & IRequest) {
  const url = `//${APP_API}/${requestUrl}`;
  const options = { method, url } as AxiosRequestConfig;

  options.headers = {
    ["x-auth-email"]: `${API_GLOBAL.email}`,
  };

  if (data) {
    options.data = data;
  }

  if (params) {
    options.params = params;
  }

  if (onUploadProgress) {
    options.onUploadProgress = onUploadProgress;
  }

  return makeRequest(options, method, url);
}
