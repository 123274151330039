import CasesPage from "./index";

const localizedCasesRoutes = (lang: string) => [
  {
    exact: true,
    path: `/${lang}`,
    component: CasesPage,
  },
];

export default localizedCasesRoutes;
