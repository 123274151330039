import React, { FC, ReactNode } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "app/rootRoutes";
import Routes from "app/Routes";
import LanguageWrapper from "./Localization/languageWrapper";
import { LanguageProvider } from "../context/languageContext";

const App: FC<ReactNode> = () => {
  return (
    <LanguageProvider>
      <LanguageWrapper>
        <Switch>
          {routes.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              render={(props) => <Routes {...item} {...props} />}
            />
          ))}
        </Switch>
      </LanguageWrapper>
    </LanguageProvider>
  );
};

export default App;
