export const SET_PENDING_FEEDBACK: string = "SET_PENDING_FEEDBACK";
export const SET_FEEDBACK: string = "SET_FEEDBACK";
export const GET_FEEDBACK_REQUEST: string = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS: string = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE: string = "GET_FEEDBACK_FAILURE";
export const GET_FEEDBACK_LOADER: string = "GET_FEEDBACK_LOADER";
export const POST_FEEDBACK_REQUEST: string = "POST_FEEDBACK_REQUEST";
export const POST_FEEDBACK_SUCCESS: string = "POST_FEEDBACK_SUCCESS";
export const POST_FEEDBACK_FAILURE: string = "POST_FEEDBACK_FAILURE";
export const POST_FEEDBACK_LOADER: string = "POST_FEEDBACK_LOADER";
