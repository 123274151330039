import {
  CLEAR_UPLOAD,
  FINISH_UPLOADING_FILE_REQUEST,
  REMOVE_FILE_REQUEST,
  SET_FILES_REQUEST,
  SET_GLOBAL_LOADING,
  SET_KEYS_REQUEST,
  SET_PROCESS_REQUEST,
  SET_PROGRESS_REQUEST,
  SET_STATUS_REQUEST,
} from "./constants";

export const setFilesRequest = (payload: any) => ({
  type: SET_FILES_REQUEST,
  payload,
});

export const removeFilesRequest = (hash: any) => ({
  type: REMOVE_FILE_REQUEST,
  payload: hash,
});

export const setProgressUploadFileRequest = (
  hash: any,
  percentage: number
) => ({
  type: SET_PROGRESS_REQUEST,
  payload: { hash, percentage },
});

export const setStatusUploadFileRequest = (hash: any, status: number) => ({
  type: SET_STATUS_REQUEST,
  payload: { hash, status },
});

export const setKeysUploadFileRequest = (
  hash: any,
  fileKey: string,
  mimeType: string
) => ({
  type: SET_KEYS_REQUEST,
  payload: { hash, fileKey, mimeType },
});

export const setLoadingGlobalUploadFileRequest = (value: any) => ({
  type: SET_GLOBAL_LOADING,
  payload: value,
});

export const finishUploadingFilesRequest = (
  uploadPackage: string,
  remoteFolder: string,
  typeUploader: string,
  successFiles: any,
  fileId: string
) => ({
  type: FINISH_UPLOADING_FILE_REQUEST,
  uploadPackage,
  remoteFolder,
  typeUploader,
  successFiles,
  fileId,
});

export const setProcessGlobalRequest = ({
  uploaded,
  totalFiles,
  remoteFolder,
  uploadPackage,
}: any) => ({
  type: SET_PROCESS_REQUEST,
  payload: { uploaded, totalFiles, remoteFolder, uploadPackage },
});

export const clearUpload = () => ({
  type: CLEAR_UPLOAD,
});
