import { useRef } from "react";

export const useThrottle = (func: any, limit: any) => {
  const inThrottle = useRef(false);
  return (...args: any) => {
    if (!inThrottle.current) {
      func(...args);
      inThrottle.current = true;
      setTimeout(() => {
        inThrottle.current = false;
      }, limit);
    }
  };
};
