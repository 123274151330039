import React, { useEffect, Suspense } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import languages from "./languages.json";
import i18n from "../../i18n";

interface LanguageWrapperProps {
  //TODO: rm later, just now have some types issues
  children: any;
}

const LanguageWrapper: React.FC<LanguageWrapperProps> = ({ children }) => {
  const { supportedLanguages } = languages;
  const location = useLocation();
  const [, lang] = location.pathname.split("/");

  useEffect(() => { i18n.changeLanguage(lang) }, [lang]);

  if (!supportedLanguages.includes(lang)) {
    return <Redirect to={`/en${location.pathname}`} />;
  }

  return (
    <Suspense fallback={<div />}>
      <Switch>{children}</Switch>
    </Suspense>
  );
};

export default LanguageWrapper;
