import React from "react";

import styles from "./index.module.scss";

const Heading = ({ onClose }: { onClose: () => void }) =>
  !!onClose && (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={styles.close}
      onClick={onClose}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.708 10.0001L15.8816 5.82648C16.2617 5.44635 16.2617 4.83192 15.8816 4.45179C15.5015 4.07165 14.887 4.07165 14.5069 4.45179L10.3333 8.62545L6.15976 4.45179C5.77963 4.07165 5.16522 4.07165 4.78509 4.45179C4.40497 4.83192 4.40497 5.44635 4.78509 5.82648L8.95867 10.0001L4.78509 14.1738C4.40497 14.5539 4.40497 15.1684 4.78509 15.5485C4.97467 15.7381 5.22355 15.8334 5.47242 15.8334C5.7213 15.8334 5.97018 15.7381 6.15976 15.5485L10.3333 11.3748L14.5069 15.5485C14.6965 15.7381 14.9454 15.8334 15.1942 15.8334C15.4431 15.8334 15.692 15.7381 15.8816 15.5485C16.2617 15.1684 16.2617 14.5539 15.8816 14.1738L11.708 10.0001Z"
        fill="white"
      />
    </svg>
  );

export default Heading;
