export const GET_CASES_REQUEST = "GET_CASES_REQUEST";
export const GET_CASES_SUCCESS = "GET_CASES_SUCCESS";
export const GET_CASES_FAILURE = "GET_CASES_FAILURE";
export const GET_CASES_LOADER = "GET_CASES_LOADER";
export const DELETE_CASE_REQUEST = "DELETE_CASE_REQUEST";
export const DELETE_CASE_STATUS_UPDATE = "DELETE_CASE_STATUS_UPDATE";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAILURE = "DELETE_CASE_FAILURE";
export const ANALYZE_REQUEST = "ANALYZE_REQUEST";
export const ANALYZE_SUCCESS = "ANALYZE_SUCCESS";
export const ANALYZE_FAILURE = "ANALYZE_FAILURE";
export const RETRY_ANALYSIS_FAILURE = "RETRY_ANALYSIS_FAILURE";
export const RETRY_ANALYSIS_REQUEST = "RETRY_ANALYSIS_REQUEST";
export const RETRY_ANALYSIS_STATUS_UPDATE = "RETRY_ANALYSIS_STATUS_UPDATE";
export const RETRY_ANALYSIS_SUCCESS = "RETRY_ANALYSIS_SUCCESS";
export const GENERATE_ASSET_REQUEST = "GENERATE_ASSET_REQUEST";
export const GENERATE_ASSET_STATUS_UPDATE = "GENERATE_ASSET_STATUS_UPDATE";
export const GENERATE_ASSET_SUCCESS = "GENERATE_ASSET_SUCCESS";
export const GENERATE_ASSET_FAILURE = "GENERATE_ASSET_FAILURE";
