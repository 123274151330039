import React from "react";
import classes from "assets/scss/style.module.scss";

const ProgressBar = (props: any) => {
  const { progressfillerStyles } = props;

  return (
    <div className={classes.ProgressBarFile}>
      <div
        style={progressfillerStyles}
        className={classes.ProgressBarFileFiller}
      />
    </div>
  );
};

export default ProgressBar;
