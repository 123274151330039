import casesSaga from "pages/Cases/redux/saga";
import { all, fork } from "redux-saga/effects";

import errorSaga from "components/Errors/saga";
import uploaderSaga from "components/Uploader/redux/saga";
import authSaga from "modules/Auth/redux/saga";
import infoSaga from "modules/Info/redux/saga";
import mriviewerSaga from "components/MRIViewer/saga";

function* rootSaga() {
  yield all([
    fork(errorSaga),
    fork(authSaga),
    fork(uploaderSaga),
    fork(casesSaga),
    fork(infoSaga),
    fork(mriviewerSaga),
  ]);
}

export default rootSaga;
