import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generateAssetRequest } from "pages/Cases/redux/actions";
import { selectAssets } from "pages/Cases/redux/selector";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import Nothing from "components/Nothing";

import Loading from "./components/Loading";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const PDFViewer = ({
  id,
  assets,
  generateAsset,
  ...rest
}: {
  id: string;
  assets: any;
  generateAsset: any;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(!assets[id]?.pdf && !assets[id]?.pdf?.length);
    !Object.keys(assets).includes(id) &&
      generateAsset({ payload: { assetType: "pdf", id, intent: "view" } });
  }, [assets]);

  if (isLoading) {
    return <Loading />;
  }

  if (assets[id]?.pdf === "") {
    return (
      <Nothing title={"404"} description={t("pages.view.pdf-viewer.report-error")} />
    );
  }

  return (
    <iframe className={styles.PDFViewer} src={assets[id]?.pdf} {...rest} />
  );
};

const mapStateToProps = createStructuredSelector({
  assets: selectAssets,
});

const withConnect = connect(mapStateToProps, {
  generateAsset: generateAssetRequest,
});

export default compose(withConnect)(PDFViewer);
