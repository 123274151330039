import { ILoaderItem } from "./types";

import {
  CLOSE_FLOW_LOADER,
  OPEN_FLOW_LOADER,
  RESET_FLOW_LOADER,
} from "./constants";

export const openFlowLoader = (item: ILoaderItem) => ({
  type: OPEN_FLOW_LOADER,
  payload: item,
});

export const closeFlowLoader = (item: ILoaderItem) => ({
  type: CLOSE_FLOW_LOADER,
  payload: item,
});

export const resetFlowLoader = (item: ILoaderItem) => ({
  type: RESET_FLOW_LOADER,
  payload: item,
});
