import { createSelector } from "reselect";

const selectShelf = (state: any) => state.shelves;

export const selectCurrentShelf = createSelector(
  [selectShelf, (state: any, ownProps: any) => ownProps.id],
  (shelfState, id) => shelfState.get(id)
);

export const makeSelectShelfOpen = createSelector(
  selectCurrentShelf,
  (state) => state && state.get("open")
);

export const makeSelectShelfProps = createSelector(
  selectCurrentShelf,
  (state) => state && state.get("props")
);
