import React, { useEffect, FC } from "react";
import { connect } from "react-redux";
import classes from "assets/scss/style.module.scss";
import { createStructuredSelector } from "reselect";

import { ModalSize, TModal } from "./types";
import { closeModal } from "components/Flows/Modal/actions";

import { makeSelectModalOpen, makeSelectModalProps } from "./selector";

const FlowModal: FC<TModal> = ({
  children,
  isModalOpen,
  handleCloseModal,
  id,
  title,
  propsModal,
  size = "big",
  onOk,
  onCancel,
  okText,
  onOkDisable,
  // ...args
}) => {
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "initial";
  }, [isModalOpen]);

  const closeAction = () => {
    if (onCancel) {
      onCancel();
    }

    return handleCloseModal({ id });
  };

  return (
    <div>
      <div
        className={`${classes.modal} ${isModalOpen && classes.active}`}
        style={{
          width: ModalSize[size],
        }}
      >
        <div className={classes.modalWrap}>
          <div className={classes.modalHead}>
            <div className={classes.modalTitle}>{title}</div>
            <button
              type={"button"}
              onClick={closeAction}
              className={classes.modalClose}
            >
              <i className={classes.iconClose} />
            </button>
          </div>
          <div className={classes.modalContent}>
            {children &&
              isModalOpen &&
              React.cloneElement(children, { ...propsModal })}
          </div>
          {(onCancel || onOk) && (
            <div className={classes.modalAction}>
              {onCancel && (
                <button
                  type={"button"}
                  onClick={closeAction}
                  className={`${classes.btn} ${classes.btnWhite}`}
                >
                  Cancel
                </button>
              )}
              {onOk && (
                <button
                  type={"button"}
                  disabled={onOkDisable}
                  onClick={onOk}
                  className={`${classes.btn} ${classes.btnBlue}`}
                >
                  {okText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${classes.ModalOverlay} ${isModalOpen && classes.active}`}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isModalOpen: makeSelectModalOpen,
  propsModal: makeSelectModalProps,
});

export default connect(mapStateToProps, { handleCloseModal: closeModal })(
  FlowModal
);
