import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Toolbar = ({
  active,
  sidebarFolded,
  handleSidebarFold,
  viewMode,
  setViewMode,
  presentation,
  setPresentation,
  segmentationOpacity,
  clippingLinesOpacity,
  measurementsVisibility,
  setSegmentationOpacity,
  setClippingLinesOpacity,
  setMeasurementsVisibility,
  pendingFeedback,
}: {
  active: boolean;
  sidebarFolded: boolean;
  handleSidebarFold: () => void;
  viewMode: string;
  setViewMode: (arg: string) => void;
  presentation: boolean;
  setPresentation: (arg: boolean) => void;
  segmentationOpacity: number;
  clippingLinesOpacity: number;
  measurementsVisibility: boolean;
  setSegmentationOpacity: (arg: number) => void;
  setClippingLinesOpacity: (arg: number) => void;
  setMeasurementsVisibility: (arg: boolean) => void;
  pendingFeedback: any;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames(styles.toolbar, {
        [styles._active]: active,
      })}
    >
      <button
        type={"button"}
        className={classnames(styles.switch, {
          [styles._switched]: sidebarFolded,
        })}
        onClick={handleSidebarFold}
      />
      <label className={styles.radioButtons}>
        <div className={styles.buttons}>
          <button
            type={"button"}
            disabled={pendingFeedback}
            className={classnames({
              [styles._active]: viewMode === "all",
            })}
            onClick={() => setViewMode("all")}
          >
            {t("components.MRIViewer.toolbar.all")}
          </button>
          <button
            type={"button"}
            disabled={pendingFeedback}
            className={classnames({
              [styles._active]: viewMode === "abnormal",
            })}
            onClick={() => setViewMode("abnormal")}
          >
            {t("components.MRIViewer.toolbar.abnormal")}
          </button>
          {/* todo edit mode disabled due to bugs in localization */}
          {/* <button
            type={"button"}
            disabled={!true}
            className={classnames({
              [styles._active]: viewMode === "edit",
            })}
            onClick={() => setViewMode("edit")}
          >
            {t("components.MRIViewer.toolbar.edit-mode")}
          </button> */}
          <button
            type={"button"}
            disabled={pendingFeedback}
            className={classnames({
              [styles._active]: viewMode === "original",
            })}
            onClick={() => setViewMode("original")}
          >
            {t("components.MRIViewer.toolbar.original")}
          </button>
        </div>
      </label>
      {setClippingLinesOpacity && (
        <label className={styles.range} data-label="levels">
          <input
            type={"range"}
            min={1}
            max={100}
            value={clippingLinesOpacity}
            onChange={({ target: { value } }) =>
              setClippingLinesOpacity(+value)
            }
            disabled={!true}
          />
          <span>{t("components.MRIViewer.toolbar.reference")}</span>
        </label>
      )}
      {setSegmentationOpacity && (
        <label className={styles.range} data-label="levels">
          <input
            type={"range"}
            min={1}
            max={100}
            value={segmentationOpacity}
            onChange={({ target: { value } }) => setSegmentationOpacity(+value)}
            disabled={viewMode === "original"}
          />
          <span>{t("components.MRIViewer.toolbar.segmentation")}</span>
        </label>
      )}
      <label className={styles.toggle} data-label="measurements">
        <input
          type="checkbox"
          defaultChecked={measurementsVisibility}
          disabled={viewMode === "original"}
          onClick={() => setMeasurementsVisibility(!measurementsVisibility)}
        />
        <span>{t("components.MRIViewer.toolbar.measurements")}</span>
      </label>
      <label className={styles.toggle} data-label="presentation">
        <input
          type="checkbox"
          defaultChecked={presentation}
          disabled={viewMode === "edit"}
          onClick={() => setPresentation(!presentation)}
        />
        <span>{t("components.MRIViewer.toolbar.presentation")}</span>
      </label>
    </div>
  );
};

export default Toolbar;
