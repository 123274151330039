export const getResearchErrorMessage = (code: string) => {
  switch (code) {
    case "INTERNAL_SERVER_ERROR": {
      return "Oops, something went wrong... Sorry, there seems to be an error with the study processing. Please try again later.";
    }

    case "EMPTY_ARCHIVE": {
      return "Oops, something went wrong... It appears that the uploaded file is an empty archive. Please make sure to upload an archive or folder containing a DICOM study.";
    }

    case "NO_TARGET_SLICES_FOUND": {
      return "Oops, something went wrong... To process your study, it must include T2 Tra and T2 Sag slices. Please verify if the study contains these slices.";
    }

    case "LOADER_ERROR": {
      return "Oops, something went wrong... Sorry, there seems to be an error with the study processing. Please try again later.";
    }

    case "NO_MRI_SLICES": {
      return "Oops, something went wrong... We couldn't find any MRI slices in the uploaded file. Please make sure to upload an MRI study.";
    }

    case "ALL_SLICES_PROCESSING_ERROR": {
      return "Oops, something went wrong... It appears that the quality of the uploaded study is poor.";
    }

    default:
      return `Oops, something went wrong... Please try again later. ${
        code && `[${code}]`
      }`;
  }
};
