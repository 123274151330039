import { fromJS } from "immutable";
import { IActionProps } from "types";

import {
  ANALYZE_FAILURE,
  ANALYZE_REQUEST,
  ANALYZE_SUCCESS,
  DELETE_CASE_FAILURE,
  DELETE_CASE_REQUEST,
  DELETE_CASE_STATUS_UPDATE,
  DELETE_CASE_SUCCESS,
  GENERATE_ASSET_FAILURE,
  GENERATE_ASSET_REQUEST,
  GENERATE_ASSET_STATUS_UPDATE,
  GENERATE_ASSET_SUCCESS,
  GET_CASES_FAILURE,
  GET_CASES_REQUEST,
  GET_CASES_SUCCESS,
  RETRY_ANALYSIS_FAILURE,
  RETRY_ANALYSIS_REQUEST,
  RETRY_ANALYSIS_STATUS_UPDATE,
  RETRY_ANALYSIS_SUCCESS,
} from "./constants";

const initialState = fromJS({
  list: null,
  polling: null,
  generating: {},
  deleting: {},
  retrying: {},
  assets: {},
}) as any;

const Cases = (state = initialState, { type, payload }: IActionProps) => {
  switch (type) {
    case GET_CASES_REQUEST: {
      return state.set("error", null);
    }
    case GET_CASES_SUCCESS: {
      return state
        .set("list", payload.list)
        .set("polling", payload.polling)
        .set("error", null);
    }
    case GET_CASES_FAILURE: {
      return state.set("list", null).set("error", payload);
    }
    case DELETE_CASE_REQUEST: {
      return state.set("error", null);
    }
    case DELETE_CASE_STATUS_UPDATE: {
      const { id, status } = payload;
      return state.setIn(["deleting", id], status).set("error", null);
    }
    case DELETE_CASE_SUCCESS: {
      return state.set("error", null);
    }
    case DELETE_CASE_FAILURE: {
      return state.set("error", payload);
    }
    case ANALYZE_REQUEST: {
      return state.set("error", null);
    }
    case ANALYZE_SUCCESS: {
      return state.set("polling", payload.polling).set("error", null);
    }
    case ANALYZE_FAILURE: {
      return state.set("polling", null).set("error", payload);
    }
    case RETRY_ANALYSIS_REQUEST: {
      return state.set("error", null);
    }
    case RETRY_ANALYSIS_STATUS_UPDATE: {
      const { id, status } = payload;
      return state.setIn(["retrying", id], status).set("error", null);
    }
    case RETRY_ANALYSIS_SUCCESS: {
      return state.set("error", null);
    }
    case RETRY_ANALYSIS_FAILURE: {
      return state.set("error", payload);
    }
    case GENERATE_ASSET_REQUEST: {
      const { assetType, id } = payload;
      const { report } = state.get("assets").toJS()[id] ?? { report: null };
      return state.setIn(["assets", id, assetType], report).set("error", null);
    }
    case GENERATE_ASSET_STATUS_UPDATE: {
      const { assetType, id, status } = payload;
      return state
        .setIn(["generating", id, assetType], status)
        .set("error", null);
    }
    case GENERATE_ASSET_SUCCESS: {
      const { assetType, id, url } = payload;
      return state.setIn(["assets", id, assetType], url).set("error", null);
    }
    case GENERATE_ASSET_FAILURE: {
      return state.set("error", payload);
    }
    default:
      return state;
  }
};

export default Cases;
