export const SET_FILES_REQUEST = "SET_FILES_REQUEST";
export const REMOVE_FILE_REQUEST = "REMOVE_FILE_REQUEST";

export const SET_PROGRESS_REQUEST = "SET_PROGRESS_REQUEST";
export const SET_STATUS_REQUEST = "SET_STATUS_REQUEST";
export const SET_KEYS_REQUEST = "SET_KEYS_REQUEST";

export const CREATE_QUEUE_REQUEST = "CREATE_QUEUE_REQUEST";
export const TAKE_FROM_QUEUE_REQUEST = "TAKE_FROM_QUEUE_REQUEST";

export const CLEAR_UPLOAD = "CLEAR_UPLOAD";
// GLOBAL
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";

export const FINISH_UPLOADING_FILE_REQUEST = "FINISH_UPLOADING_FILE_REQUEST";
export const SET_PROCESS_REQUEST = "SET_PROCESS_REQUEST";
