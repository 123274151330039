import createAction from "utils/createAction";

import {
  RESET_PROGRESS_FILE,
  START_PROGRESS_FILE,
  STOP_PROGRESS_FILE,
  UPLOAD_FILE_REQUEST,
} from "./constants";

export const uploadFileRequest = createAction(UPLOAD_FILE_REQUEST);

export const startProgressFile = (item: any) => ({
  type: START_PROGRESS_FILE,
  payload: item,
});

export const finishProgressFile = (item: any) => ({
  type: STOP_PROGRESS_FILE,
  payload: item,
});

export const resetProgressFile = (item: any) => ({
  type: RESET_PROGRESS_FILE,
  payload: item,
});
