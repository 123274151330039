import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classes from "assets/scss/style.module.scss";
import cn from "classnames";
import { TaskQueue } from "cwait";
import { createStructuredSelector } from "reselect";

import { toast } from "components/Flows/Toast/core";
import { selectUser } from "modules/Auth/redux/selector";
import { UploaderDefault } from "utils/upload";
import { UploaderMultipart } from "utils/uploadMultipart";

import { request } from "../../api";
import { Services } from "../../constants/services";
import { uploadFileRequest } from "../../modules/MRI/redux/actions";
import { uniqId } from "../../utils/initials";

import {
  finishUploadingFilesRequest,
  removeFilesRequest,
  setFilesRequest,
  setKeysUploadFileRequest,
  setLoadingGlobalUploadFileRequest,
  setProcessGlobalRequest,
  setProgressUploadFileRequest,
  setStatusUploadFileRequest,
} from "./redux/actions";
import { makeSelectUploaderFiles } from "./redux/selector";
import DropZone from "./DropZone";
// import { getStatusFiller, getStatusTitle } from './FileItem'
import FileList from "./FileList";
import { useTranslation } from "react-i18next";
// import ProgressBar from './ProgressBar'

const MAX_SIMULTANEOUS_DOWNLOADS = 5;

const UploaderComponent = ({
  id,
  user,
  caseId, // todo remove ?
  uploadTrigger,
  setUploadTrigger,
  offTrigger,
  fileId,
  files = [],
  // global,
  setProcessGlobal,
  finishUploadingFiles,
  setKeysUploadFile,
  setStatusUploadFile,
  setFilesReducer,
  setProgressUploadFile,
  typeUploader,
  setLoadingGlobalUploadFile,
  removeFiles,
}: any) => {
  const { t } = useTranslation();
  const [specificId, setSpecificId] = useState<string>("");

  useEffect(() => {
    if (uploadTrigger) {
      uploadNew();
      offTrigger();
    }
  }, [uploadTrigger]);

  const uploadByMultipart = (
    file: any,
    remoteFolder: string,
    uploadPackage: string,
    running: number
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (file) {
        const videoUploaderOptions = {
          fileName: `${file?.hash}_${file?.name}`,
          file,
          mimeType: file.type,
          remoteFolder,
          uploadPackage,
          caseId: file?.caseId, // todo remove ?
          email: user.email,
        };

        let percentage: any;

        const uploader = new UploaderMultipart(videoUploaderOptions);
        uploader
          .onProgress(({ percentage: newPercentage }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              setStatusUploadFile(file.hash, "loading");
              setProgressUploadFile(file.hash, percentage);
            }
          })
          .onError((error: any) => {
            setStatusUploadFile(file.hash, "error");
            reject();
            console.error(error);
          })
          .onSuccess((response: any) => {
            setProcessGlobal({
              uploaded: running,
              totalFiles: files.length,
              remoteFolder,
              uploadPackage,
            });
            setStatusUploadFile(file.hash, "success");
            setKeysUploadFile(
              file.hash,
              response?.data?.fileKey,
              response?.data?.mimeType
            );
            resolve();
          });

        uploader.start();
      }
    });
  };

  const uploadByDefault = (
    file: any,
    remoteFolder: string,
    uploadPackage: string,
    running: number
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (file) {
        const videoUploaderOptions = {
          fileName: `${file?.name}_${file?.hash}`,
          file,
          mimeType: file.type,
          remoteFolder,
          uploadPackage,
          caseId: file?.caseId, // todo remove ?
        };

        let percentage: any;

        const uploader = new UploaderDefault(videoUploaderOptions);
        uploader
          .onProgress(({ percentage: newPercentage }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              setStatusUploadFile(file.hash, "loading");
              setProgressUploadFile(file.hash, percentage);
            }
          })
          .onError((error: any) => {
            setStatusUploadFile(file.hash, "error");
            // clearFiles()
            reject();
            console.error(error);
          })
          .onSuccess((fileKey: string, mimeType: string) => {
            resolve();
            setProcessGlobal({
              uploaded: running,
              totalFiles: files.length,
              remoteFolder,
              uploadPackage,
            });
            setStatusUploadFile(file.hash, "success");
            setKeysUploadFile(file.hash, fileKey, mimeType);
          });

        uploader.start();
      }
    });
  };

  const uploadNew = async () => {
    try {
      setLoadingGlobalUploadFile(true);
      const genId = uniqId();
      setSpecificId(genId);
      // @ts-ignore
      const { remoteFolder, uploadPackage } = await request({
        service: Services.REMEDY_CORE,
        url: `v1/file/uploads/generatePackageUUID`,
        method: "POST",
        data: {
          ...user,
          prefix: `${Date.now()}_${user.email}`,
        },
      });

      const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
      let running = 0;

      await Promise.all(
        files.map(
          queue.wrap(async (file: any) => {
            ++running;
            // if (file.size > 5543751) {
            return await uploadByMultipart(
              file,
              remoteFolder,
              uploadPackage,
              running
            );
            // }
            // return await uploadByDefault(
            //   file,
            //   remoteFolder,
            //   uploadPackage,
            //   running
            // );
          })
        )
      );

      finishUploadingFiles(
        uploadPackage,
        remoteFolder,
        typeUploader,
        files,
        fileId
      );
      setLoadingGlobalUploadFile(false);
    } catch (error) {
      console.error(error);
      toast.success(
        {
          title: error?.message ?? t("components.uploader.error"),
        },
        { autoClose: 5000, type: "error" }
      );
    }
  };

  const onFilesDrop = (files: File[]) => {
    setFilesReducer(files);
    setUploadTrigger(true);
  };

  const onDelete = (item: any) => {
    removeFiles(item?.hash);
  };

  const haveFiles = files?.length > 0;
  // const globalPersents = Math.round((global.uploaded / global.totalFiles) * 100)
  return (
    <div className={classes.Upload}>
      {/* <div>
        {global.uploaded !== 0 && (
          <>
            <div className={classes.UploadFileListContainerItemTitle}>
              Total uploading status
            </div>
            <ProgressBar
              progressfillerStyles={{
                width: `${globalPersents}%`,
                backgroundColor: getStatusFiller(globalPersents, status),
              }}
            />
            <div className={classes.UploadFileListContainerItemDescription}>
              {getStatusTitle(globalPersents, status)}
            </div>
          </>
        )}
      </div> */}
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <DropZone
          className={cn(classes.dropZone, {
            [classes.dropZoneHaveFiles]: haveFiles,
          })}
          onFilesDrop={onFilesDrop}
          typeUploader={typeUploader}
          setSpecificId={setSpecificId}
        />
        <div
          className={cn(classes.UploadFileList, {
            [classes.UploadFileListActive]: haveFiles,
          })}
        >
          <FileList id={specificId} onDelete={onDelete} files={files} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  files: makeSelectUploaderFiles(),
  user: selectUser,
});

export default connect(mapStateToProps, {
  upload: uploadFileRequest,
  setFilesReducer: setFilesRequest,
  removeFiles: removeFilesRequest,
  setProgressUploadFile: setProgressUploadFileRequest,
  setStatusUploadFile: setStatusUploadFileRequest,
  setKeysUploadFile: setKeysUploadFileRequest,
  finishUploadingFiles: finishUploadingFilesRequest,
  setLoadingGlobalUploadFile: setLoadingGlobalUploadFileRequest,
  setProcessGlobal: setProcessGlobalRequest,
})(UploaderComponent);
