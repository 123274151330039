import React, { FC, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getCasesRequest } from "pages/Cases/redux/actions";
import { selectCases } from "pages/Cases/redux/selector";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { IRouterProps } from "types";

import { authenticateRequest } from "modules/Auth/redux/actions";
import { selectUser } from "modules/Auth/redux/selector";
import { getPathologiesListRequest } from "modules/Info/redux/actions";
import { useLanguage } from "../../context/languageContext";

const PublicLayout = lazy(() => import("app/Layouts/public"));

const Routes: FC<IRouterProps> = ({
  path,
  user,
  cases,
  getCases,
  authenticate,
  getPathologiesList,
  component: Component,
}) => {
  const lang = useLanguage();

  useEffect(() => {
    !user && authenticate();
    getPathologiesList();
  }, [user]);

  useEffect(() => {
    !!user && !cases && getCases({ payload: { _silent: false } });
  }, [user, cases]);

  const getRedirectPath = (path: string) => `/${lang}${path}`;

  return (
    <Suspense fallback={<></>}>
      <PublicLayout>
        <Route
          path={path}
          render={() => {
            if (
              (!user || (cases && cases.length === 0)) &&
              path !== getRedirectPath("/start")
            ) {
              return <Redirect to={getRedirectPath("/start")} />;
            }

            if (
              user &&
              cases &&
              cases.length > 0 &&
              path === getRedirectPath("/start")
            ) {
              return <Redirect to={getRedirectPath("/")} />;
            }

            return <Component />;
          }}
        />
      </PublicLayout>
    </Suspense>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  cases: selectCases,
});

const withConnect = connect(mapStateToProps, {
  authenticate: authenticateRequest,
  getCases: getCasesRequest,
  getPathologiesList: getPathologiesListRequest,
});

export default compose(withConnect)(Routes);
