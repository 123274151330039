import _ from "underscore";

type IInitials = {
  firstName: string;
  lastName: string;
};

export const uniqId = () => {
  return `id${Math.random().toString(16).slice(2)}`;
};

export default ({ firstName, lastName }: IInitials) => {
  return `${_.first(firstName)}${_.first(lastName)}`.toUpperCase();
};
