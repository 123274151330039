import axios from "axios";
const APP_API = process.env.REACT_APP_API;
// initializing axios
const api = axios.create({
  baseURL: `//${APP_API}`,
});

export class UploaderDefault {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise Storage will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.remoteFolder = options.remoteFolder;
    this.uploadPackage = options.uploadPackage;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.mimeType = options.mimeType;
    this.caseId = options.caseId; // todo remove ?
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onSuccessFn = () => {};
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      let fileName = this.fileName;

      const { data } = await api.request({
        url: "/v1/file/uploads/getDefaultPreSignedUrls",
        method: "POST",
        data: { key: `${remoteFolder}/${fileName}` },
      });

      const { uploadURL, fileKey } = data;

      this.sendNext(uploadURL, fileKey, this.mimeType);
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext(uploadURL, fileKey, mimeType) {
    if (this.file) {
      const chunk = this.file;

      this.sendChunk(chunk, uploadURL)
        .then(() => {
          this.complete(null, fileKey, mimeType);
        })
        .catch((error) => {
          this.complete(error);
        });
    }
  }

  sendChunk(chunk, uploadURL) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, uploadURL)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  upload(file, uploadURL) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (file && uploadURL) {
        const xhr = new XMLHttpRequest();

        const progressListener = this.handleProgress.bind(this);

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", uploadURL);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            resolve(xhr.status);
          }
        };

        xhr.onerror = (error) => {
          reject(error);
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
        };

        xhr.send(file);
      }
    });
  }

  async complete(error, fileKey, mimeType) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      this.onSuccessFn(fileKey, mimeType);
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  handleProgress(event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache || 0;
        delete this.progressCache;
      }

      const inProgress = this.progressCache;

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent,
        total,
        percentage,
      });
    }
  }

  onSuccess(onSuccess) {
    this.onSuccessFn = onSuccess;
    return this;
  }
  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
