import { fromJS } from "immutable";

import {
  SET_PENDING_FEEDBACK,
  SET_FEEDBACK,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
  POST_FEEDBACK_LOADER,
} from "./utils/constants";

const initialState = fromJS({
  feedback: null,
  pendingFeedback: null,
  loader: null,
  error: null,
}) as any;

const mriviewer = (state = initialState, { type, ...rest }: any) => {
  const payload = Object.keys(rest).length ? rest : null;
  switch (type) {
    case SET_PENDING_FEEDBACK: {
      return state.set("pendingFeedback", payload);
    }
    case SET_FEEDBACK: {
      return state.set("feedback", payload);
    }
    case GET_FEEDBACK_REQUEST: {
      return state.set("error", null);
    }
    case GET_FEEDBACK_SUCCESS: {
      return state.set("feedback", payload.payload).set("error", null);
    }
    case GET_FEEDBACK_FAILURE: {
      return state.set("error", payload);
    }
    case POST_FEEDBACK_REQUEST: {
      return state.set("error", null);
    }
    case POST_FEEDBACK_SUCCESS: {
      return state.set("error", null);
    }
    case POST_FEEDBACK_FAILURE: {
      return state.set("error", payload);
    }
    case POST_FEEDBACK_LOADER: {
      return state.set("loader", payload.payload).set("error", null);
    }
    default:
      return state;
  }
};

export default mriviewer;
