import React, { FC } from "react";

import Loading from "../Loading";

import styles from "./index.module.scss";

type Props = {
  loaded: boolean;
};

const Content: FC<Props> = ({ loaded, children }) => (
  <div className={styles.content}>
    {loaded && children ? children : <Loading />}
  </div>
);

export default Content;
