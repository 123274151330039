import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from "components/Button";
import { openModal } from "components/Flows/Modal/actions";
import StartAnalysis from "components/StartAnalysis";
import Features from "components/Features";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface StartPageProps {
  handleOpenModal: ({ id }: { id: string }) => void;
}

const StartPage = ({ handleOpenModal }: StartPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.start}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <span>
            {t("pages.start.studies")}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z"
              />
            </svg>
          </span>
          <Button
            variant={"white"}
            target={"_blank"}
            rel={"noreferrer"}
            href={"https://calendly.com/remedylogic/demo"}
          >
            {t("pages.start.version")}
          </Button>
        </div>
        <div className={styles.startScreen}>
          <div>
            <h1>
              <svg
                width="31"
                height="41"
                viewBox="0 0 31 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9146 6.57282C20.9173 7.70488 20.7294 8.82946 20.3586 9.90014C20.2163 10.3067 20.0477 10.7038 19.8541 11.0889H6.22221V0.338867H18.7551C20.1589 2.11814 20.9194 4.31354 20.9146 6.57282Z"
                  fill="#1365FC"
                />
                <path
                  d="M17.6772 25.5736H0.743652V15.089H17.6739C17.4261 15.4607 17.2066 15.8488 17.0171 16.2502C16.3071 17.7378 15.9956 19.3686 16.1102 20.9977C16.2248 22.6268 16.7619 24.204 17.6739 25.5891L17.6772 25.5736Z"
                  fill="#1365FC"
                />
                <path
                  d="M20.9146 34.1053C20.9167 35.8741 20.4523 37.6129 19.5675 39.1484C19.3293 39.5649 19.0595 39.9629 18.7606 40.3389H6.22221V29.5888H19.8536C20.0444 29.9756 20.2119 30.3731 20.3552 30.7793C20.7275 31.8491 20.9166 32.9733 20.9146 34.1053Z"
                  fill="#1365FC"
                />
              </svg>
              {t("pages.start.radiology-ai")}
            </h1>
            <p>
              <strong>{t("pages.start.RAI")}</strong>
            </p>
            <p>{t("pages.start.lumbar")}</p>
            <div className={styles.ext} />
            <Button
              className={styles.note}
              variant={"simple"}
              onClick={() => handleOpenModal({ id: "study-requirements" })}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6C11 6.552 10.552 7 10 7C9.448 7 9 6.552 9 6ZM9 9C9 8.448 9.448 8 10 8C10.552 8 11 8.448 11 9V14C11 14.552 10.552 15 10 15C9.448 15 9 14.552 9 14V9ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.522 20 20 15.523 20 10C20 4.477 15.522 0 10 0Z"
                />
              </svg>
              {t("pages.start.study-requirements")}
            </Button>
            {/* <Button
            className={styles.note}
            variant={"simple"}
            onClick={() => handleOpenModal({ id: "pathologies-list" })}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6C11 6.552 10.552 7 10 7C9.448 7 9 6.552 9 6ZM9 9C9 8.448 9.448 8 10 8C10.552 8 11 8.448 11 9V14C11 14.552 10.552 15 10 15C9.448 15 9 14.552 9 14V9ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.522 20 20 15.523 20 10C20 4.477 15.522 0 10 0Z"
              />
            </svg>
            Covered pathologies
          </Button> */}
          </div>
          <StartAnalysis />
        </div>
      </div>
      <h2>{t("pages.start.radiology-ai-identifies")}:</h2>
      <Features />
    </div>
  );
};

const withConnect = connect(null, {
  handleOpenModal: openModal,
});

export default compose(withConnect)(StartPage);
