import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import languages from "../app/Localization/languages.json";

const LanguageContext = createContext<string>("en");

export const LanguageProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [, lang] = location.pathname.split("/");

  const { supportedLanguages } = languages;
  const currentLanguage = supportedLanguages.includes(lang) ? lang : "en";

  const value = useMemo(() => currentLanguage, [currentLanguage]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
