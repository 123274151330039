import { all, call, put, takeLatest } from "redux-saga/effects";

import API from "../api";

import { getPathologiesListSucces, getPathologiesListFailure } from "./actions";
import { GET_PATHOLOGIES_LIST_REQUEST } from "./constants";

function* getPathologiesList() {
  try {
    const payload = yield call(API.getPathologies);
    yield put(getPathologiesListSucces({ payload }));
  } catch (error) {
    yield put(getPathologiesListFailure(error));
  }
}

function* Saga() {
  yield all([takeLatest(GET_PATHOLOGIES_LIST_REQUEST, getPathologiesList)]);
}

export default Saga;
