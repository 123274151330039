import { all, call, delay, put, takeLatest } from "redux-saga/effects";

import {
  closeFlowLoader,
  openFlowLoader,
} from "components/Flows/Loader/actions";
import { openModal } from "components/Flows/Modal/actions";
import { toast } from "components/Flows/Toast/core";
import { validateEmailFormat } from "utils/validates";

import API from "../api";

import {
  authenticateFailure,
  authenticateSuccess,
  registerFailure,
  registerSuccess,
  validateEmailFailure,
  validateEmailSuccess,
} from "./actions";
import {
  AUTHENTICATE_LOADER,
  AUTHENTICATE_REQUEST,
  REGISTER_REQUEST,
  VALIDATE_EMAIL_REQUEST,
} from "./constants";

function* authenticate() {
  try {
    yield put(openFlowLoader({ id: AUTHENTICATE_LOADER }));
    const payload = yield call(API.info, {});
    yield put(authenticateSuccess({ payload }));
    yield payload && delay(500);
    return yield put(closeFlowLoader({ id: AUTHENTICATE_LOADER }));
  } catch (error) {
    yield put(authenticateFailure());
    return yield put(closeFlowLoader({ id: AUTHENTICATE_LOADER }));
  }
}

function* validateEmail({ payload }: any) {
  try {
    yield put(
      validateEmailSuccess({
        payload: { email: false },
      })
    );
    if (validateEmailFormat(payload)) {
      yield call(API.validate, { email: payload });
      return yield put(
        validateEmailSuccess({
          payload: { email: false },
        })
      );
    }
  } catch (error) {
    console.error(error);
    return yield put(
      validateEmailFailure({
        payload: { email: "Email already in use" },
      })
    );
  }
}

function* register({ payload }: any) {
  if (payload.user) {
    try {
      yield call(API.update, {
        ...payload.fields,
        country: "Liberland",
      });
      yield put(
        registerSuccess({
          payload: {
            ...payload.fields,
            country: "Liberland",
          },
        })
      );
      yield put(
        openModal({
          id: "uploader",
          // @ts-ignore
          props: payload.user,
        })
      );
    } catch (error) {
      console.error(error);
      toast.success(
        { title: error?.message ?? "Failed to update user info" },
        { autoClose: 5000, type: "error" }
      );
      return yield put(registerFailure());
    }
  } else {
    try {
      yield call(API.register, {
        ...payload.fields,
        country: "Liberland",
      });
      yield put(
        registerSuccess({
          payload: {
            ...payload.fields,
            country: "Liberland",
          },
        })
      );
      yield put(
        openModal({
          id: "uploader",
          // @ts-ignore
          props: { ...payload.fields },
        })
      );
    } catch (error) {
      console.error(error);
      toast.success(
        { title: error?.message ?? "Failed to register" },
        { autoClose: 5000, type: "error" }
      );
      return yield put(registerFailure());
    }
  }
}

function* Saga() {
  yield all([takeLatest(AUTHENTICATE_REQUEST, authenticate)]);
  yield all([takeLatest(VALIDATE_EMAIL_REQUEST, validateEmail)]);
  yield all([takeLatest(REGISTER_REQUEST, register)]);
}

export default Saga;
