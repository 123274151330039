import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import 'moment/locale/es'
import {
  deleteCaseRequest,
  generateAssetRequest,
  retryAnalysisRequest,
} from "pages/Cases/redux/actions";
import {
  selectCases,
  selectDeleting,
  selectGenerating,
  selectRetrying,
} from "pages/Cases/redux/selector";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Button, { ButtonGroup, ButtonLoading } from "components/Button";
import { openModal } from "components/Flows/Modal/actions";
import StatusBadge from "components/StatusBadge";
import { selectUser } from "modules/Auth/redux/selector";
import { getResearchErrorMessage } from "utils/researchErrorMessages";
import { useLanguage } from "../../context/languageContext";
import styles from "./index.module.scss";

const Cases = ({
  cases,
  generating,
  deleting,
  retrying,
  deleteCase,
  retryAnalysis,
  generateAsset,
  handleOpenModal,
}: {
  cases?: any;
  generating: any;
  deleting: any;
  retrying: any;
  deleteCase: any;
  retryAnalysis: any;
  generateAsset: any;
  handleOpenModal: any;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const history = useHistory();

  if (!cases || !cases?.length) {
    return <div />;
  }

  const Casee = ({ c }: { c: any }) => {
    if (!c) {
      return null;
    }
    const status =
      c.status?.toLowerCase().includes("failure") ||
      c.status?.toLowerCase().includes("error")
        ? "error"
        : c.status?.toLowerCase().includes("complete")
        ? "done"
        : "processing";

    return (
      <div className={styles.case} title={c.id}>
        <div className={styles.content}>
          <div className={styles.caseHeading}>
            <div className={styles.caseTitle}>
              {t("pages.cases.study-from")}{" "}
              {moment(c.updatedAt || c.createdAt).locale(language).format(
                "MMMM D, YYYY (hh:mm A)"
              )}
            </div>
            {status && (
              <StatusBadge
                status={status}
                message={
                  status === "error"
                    ? getResearchErrorMessage(c.error?.message)
                    : ""
                }
              />
            )}
          </div>
          {status === "done" && c.dicom_report_available === null && (
            <div className={styles.caseDownloadStatus}>
              <span />
              {t("pages.cases.generating")}
            </div>
          )}
          <span />
          <div className={styles.caseFunctions}>
            {status !== "error" && (
              <Button
                disabled={
                  c.status?.includes("REQUEST") ||
                  !c.image_analyzer_result?.files
                }
                size={"small"}
                onClick={() => history.push(`/${language}/${c.id}`)}
              >
                {t("pages.cases.view")}
              </Button>
            )}
            {status === "error" && (
              <>
                <Button
                  disabled={!c.id || !c.file?.key || !c.file?.upload_package}
                  variant={"outline"}
                  size={"small"}
                  onClick={() =>
                    retryAnalysis({
                      payload: {
                        id: c.id,
                        remoteFolder: c.file?.key,
                        uploadPackage: c.file?.upload_package,
                        language,
                      },
                    })
                  }
                >
                  {t("pages.cases.retry")}
                  <ButtonLoading isLoading={retrying?.[c.id]} />
                </Button>
                <Button
                  disabled={!c.id || deleting?.[c.id]}
                  variant={"simple"}
                  size={"small"}
                  onClick={() => deleteCase({ payload: { id: c.id } })}
                >
                  {t("pages.cases.delete")}
                  <ButtonLoading isLoading={deleting?.[c.id]} />
                </Button>
              </>
            )}
            {status !== "error" && (
              <ButtonGroup>
                <Button
                  disabled={
                    c.status?.includes("REQUEST") ||
                    !c.dicom_report_available ||
                    generating?.[c.id]?.dicom
                  }
                  size={"small"}
                  variant={"default"}
                  onClick={() =>
                    generateAsset({
                      payload: {
                        assetType: "dicom",
                        id: c.id,
                        intent: "download",
                      },
                    })
                  }
                >
                  {t("pages.cases.dicom")}
                  <ButtonLoading isLoading={generating?.[c.id]?.dicom}>
                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.16895 8.06325C6.16738 8.04237 6.16659 8.02128 6.16659 8V1.33333C6.16659 0.873333 6.53909 0.5 6.99992 0.5C7.46075 0.5 7.83325 0.873333 7.83325 1.33333V7.99985L9.83325 6.5C10.2016 6.2225 10.7241 6.29833 10.9999 6.66667C11.2766 7.035 11.2016 7.5575 10.8333 7.83333L7.49992 10.3333C7.35242 10.4442 7.17575 10.5 6.99992 10.5C6.83242 10.5 6.66492 10.4492 6.52075 10.3483L3.18742 8.00333C2.81075 7.73833 2.71992 7.21833 2.98492 6.8425C3.24992 6.46583 3.76909 6.375 4.14575 6.64L6.16895 8.06325ZM1.99992 12.1667V13H11.9999V12.1667C11.9999 11.7083 12.3749 11.3333 12.8333 11.3333C13.2916 11.3333 13.6666 11.7083 13.6666 12.1667V13.8333C13.6666 14.2917 13.2916 14.6667 12.8333 14.6667H1.16659C0.708252 14.6667 0.333252 14.2917 0.333252 13.8333V12.1667C0.333252 11.7083 0.708252 11.3333 1.16659 11.3333C1.62492 11.3333 1.99992 11.7083 1.99992 12.1667Z"
                      />
                    </svg>
                  </ButtonLoading>
                </Button>
                <Button
                  disabled={
                    c.status?.includes("REQUEST") ||
                    !c.dicom_report_available ||
                    generating?.[c.id]?.docx
                  }
                  size={"small"}
                  variant={"default"}
                  onClick={() =>
                    generateAsset({
                      payload: {
                        assetType: "docx",
                        id: c.id,
                        intent: "download",
                      },
                    })
                  }
                >
                  {t("pages.cases.report")}{" "}
                  <ButtonLoading isLoading={generating?.[c.id]?.docx}>
                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.16895 8.06325C6.16738 8.04237 6.16659 8.02128 6.16659 8V1.33333C6.16659 0.873333 6.53909 0.5 6.99992 0.5C7.46075 0.5 7.83325 0.873333 7.83325 1.33333V7.99985L9.83325 6.5C10.2016 6.2225 10.7241 6.29833 10.9999 6.66667C11.2766 7.035 11.2016 7.5575 10.8333 7.83333L7.49992 10.3333C7.35242 10.4442 7.17575 10.5 6.99992 10.5C6.83242 10.5 6.66492 10.4492 6.52075 10.3483L3.18742 8.00333C2.81075 7.73833 2.71992 7.21833 2.98492 6.8425C3.24992 6.46583 3.76909 6.375 4.14575 6.64L6.16895 8.06325ZM1.99992 12.1667V13H11.9999V12.1667C11.9999 11.7083 12.3749 11.3333 12.8333 11.3333C13.2916 11.3333 13.6666 11.7083 13.6666 12.1667V13.8333C13.6666 14.2917 13.2916 14.6667 12.8333 14.6667H1.16659C0.708252 14.6667 0.333252 14.2917 0.333252 13.8333V12.1667C0.333252 11.7083 0.708252 11.3333 1.16659 11.3333C1.62492 11.3333 1.99992 11.7083 1.99992 12.1667Z"
                      />
                    </svg>
                  </ButtonLoading>
                </Button>
              </ButtonGroup>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.cases}>
      <div className={styles.casesRequirements}>
        <p>
          {t("pages.cases.update-lumbar")}
        </p>
        <div className={styles.notesRow}>
          <Button
            className={styles.note}
            variant={"simple"}
            onClick={() => handleOpenModal({ id: "study-requirements" })}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6C11 6.552 10.552 7 10 7C9.448 7 9 6.552 9 6ZM9 9C9 8.448 9.448 8 10 8C10.552 8 11 8.448 11 9V14C11 14.552 10.552 15 10 15C9.448 15 9 14.552 9 14V9ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.522 20 20 15.523 20 10C20 4.477 15.522 0 10 0Z"
              />
            </svg>
            {t("pages.cases.study-requirements")}
          </Button>
          {/* <Button
            className={styles.note}
            variant={"simple"}
            onClick={() => handleOpenModal({ id: "pathologies-list" })}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 6C9 5.448 9.448 5 10 5C10.552 5 11 5.448 11 6C11 6.552 10.552 7 10 7C9.448 7 9 6.552 9 6ZM9 9C9 8.448 9.448 8 10 8C10.552 8 11 8.448 11 9V14C11 14.552 10.552 15 10 15C9.448 15 9 14.552 9 14V9ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.522 20 20 15.523 20 10C20 4.477 15.522 0 10 0Z"
              />
            </svg>
            Covered pathologies
          </Button> */}
        </div>
      </div>
      {cases && (
        <div className={styles.casesList}>
          {cases.map((c: any, key: number) => (
            <Casee key={key} c={c} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  cases: selectCases,
  generating: selectGenerating,
  deleting: selectDeleting,
  retrying: selectRetrying,
});

const withConnect = connect(mapStateToProps, {
  deleteCase: deleteCaseRequest,
  retryAnalysis: retryAnalysisRequest,
  generateAsset: generateAssetRequest,
  handleOpenModal: openModal,
});

export default compose(withConnect)(Cases);
