import React from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { generateAssetRequest } from "pages/Cases/redux/actions";
import { selectCases, selectGenerating } from "pages/Cases/redux/selector";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import Button, { ButtonLoading } from "components/Button";
import MRIViewer from "components/MRIViewer";
import PDFViewer from "components/PDFViewer";
import Viewer from "components/Viewer";
import { selectUser } from "modules/Auth/redux/selector";
import { selectPathologiesList } from "modules/Info/redux/selector";

import { useLanguage } from "../../context/languageContext";

type ViewParams = {
  id: string;
};

const Cases = ({
  user,
  cases,
  generating,
  generateAsset,
  pathologiesList,
}: {
  user?: any;
  cases?: any;
  generating: any;
  generateAsset: any;
  pathologiesList: any;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const history = useHistory();
  const { id, ...rest } = useParams<ViewParams>();

  if (!cases || !cases?.length) {
    return <div />;
  }

  const casee = cases?.find((c: any) => c.id === id);

  if (!casee) {
    return <div />;
  }

  return (
    <Viewer
      tabs={[
        t("pages.view.viewer.tabs.enhanced"),
        casee.pdf_report_available ? t("pages.view.viewer.tabs.report") : null,
      ]}
      onClose={() =>
        history.length < 3 ? history.push(`/${language}`) : history.goBack()
      }
      fullscreen={true}
    >
      <div>
        <Button
          disabled={
            casee.status.includes("REQUEST") ||
            !casee.dicom_report_available ||
            generating?.[casee.id]?.dicom
          }
          size={"small"}
          variant={"grey"}
          onClick={() =>
            generateAsset({
              payload: {
                assetType: "dicom",
                id: casee.id,
                intent: "download",
              },
            })
          }
        >
          {t("pages.view.download-dicom")}{" "}
          <ButtonLoading isLoading={generating?.[casee.id]?.dicom}>
            <svg
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.16895 8.06325C6.16738 8.04237 6.16659 8.02128 6.16659 8V1.33333C6.16659 0.873333 6.53909 0.5 6.99992 0.5C7.46075 0.5 7.83325 0.873333 7.83325 1.33333V7.99985L9.83325 6.5C10.2016 6.2225 10.7241 6.29833 10.9999 6.66667C11.2766 7.035 11.2016 7.5575 10.8333 7.83333L7.49992 10.3333C7.35242 10.4442 7.17575 10.5 6.99992 10.5C6.83242 10.5 6.66492 10.4492 6.52075 10.3483L3.18742 8.00333C2.81075 7.73833 2.71992 7.21833 2.98492 6.8425C3.24992 6.46583 3.76909 6.375 4.14575 6.64L6.16895 8.06325ZM1.99992 12.1667V13H11.9999V12.1667C11.9999 11.7083 12.3749 11.3333 12.8333 11.3333C13.2916 11.3333 13.6666 11.7083 13.6666 12.1667V13.8333C13.6666 14.2917 13.2916 14.6667 12.8333 14.6667H1.16659C0.708252 14.6667 0.333252 14.2917 0.333252 13.8333V12.1667C0.333252 11.7083 0.708252 11.3333 1.16659 11.3333C1.62492 11.3333 1.99992 11.7083 1.99992 12.1667Z"
              />
            </svg>
          </ButtonLoading>
        </Button>
        <MRIViewer
          researchId={casee.id}
          auth={user?.email}
          analysisData={casee.image_analyzer_result}
          pathologiesList={pathologiesList}
        />
      </div>
      <div>
        <Button
          disabled={generating?.[casee.id]?.docx}
          size={"small"}
          variant={"grey"}
          onClick={() =>
            generateAsset({
              payload: {
                assetType: "docx",
                id: casee.id,
                intent: "download",
              },
            })
          }
        >
          {t("pages.view.download-report")}{" "}
          <ButtonLoading isLoading={generating?.[casee.id]?.docx}>
            <svg
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.16895 8.06325C6.16738 8.04237 6.16659 8.02128 6.16659 8V1.33333C6.16659 0.873333 6.53909 0.5 6.99992 0.5C7.46075 0.5 7.83325 0.873333 7.83325 1.33333V7.99985L9.83325 6.5C10.2016 6.2225 10.7241 6.29833 10.9999 6.66667C11.2766 7.035 11.2016 7.5575 10.8333 7.83333L7.49992 10.3333C7.35242 10.4442 7.17575 10.5 6.99992 10.5C6.83242 10.5 6.66492 10.4492 6.52075 10.3483L3.18742 8.00333C2.81075 7.73833 2.71992 7.21833 2.98492 6.8425C3.24992 6.46583 3.76909 6.375 4.14575 6.64L6.16895 8.06325ZM1.99992 12.1667V13H11.9999V12.1667C11.9999 11.7083 12.3749 11.3333 12.8333 11.3333C13.2916 11.3333 13.6666 11.7083 13.6666 12.1667V13.8333C13.6666 14.2917 13.2916 14.6667 12.8333 14.6667H1.16659C0.708252 14.6667 0.333252 14.2917 0.333252 13.8333V12.1667C0.333252 11.7083 0.708252 11.3333 1.16659 11.3333C1.62492 11.3333 1.99992 11.7083 1.99992 12.1667Z"
              />
            </svg>
          </ButtonLoading>
        </Button>
        <PDFViewer id={casee.id} />
      </div>
    </Viewer>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  cases: selectCases,
  generating: selectGenerating,
  pathologiesList: selectPathologiesList,
});

const withConnect = connect(mapStateToProps, {
  generateAsset: generateAssetRequest,
});

export default compose(withConnect)(Cases);
