import { fromJS } from "immutable";
import { IActionProps } from "types";

import {
  AUTHENTICATE_FAILURE,
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  VALIDATE_EMAIL_REQUEST,
  VALIDATE_EMAIL_SUCCESS,
} from "./constants";

const initialState = fromJS({
  validation: null,
  user: null,
  error: null,
}) as any;

const auth = (state = initialState, { type, payload }: IActionProps) => {
  switch (type) {
    case AUTHENTICATE_REQUEST: {
      return state.set("error", null);
    }
    case AUTHENTICATE_SUCCESS: {
      return state.set("user", payload).set("error", null);
    }
    case AUTHENTICATE_FAILURE: {
      return state.set("user", null).set("error", payload);
    }
    case VALIDATE_EMAIL_REQUEST: {
      return state.set("error", null);
    }
    case VALIDATE_EMAIL_SUCCESS: {
      return state.set("validation", payload).set("error", null);
    }
    case VALIDATE_EMAIL_FAILURE: {
      return state.set("validation", payload).set("error", null);
    }
    case REGISTER_REQUEST: {
      return state.set("error", null);
    }
    case REGISTER_SUCCESS: {
      return state.set("user", payload).set("error", null);
    }
    case REGISTER_FAILURE: {
      return state.set("user", null).set("error", payload);
    }
    default:
      return state;
  }
};

export default auth;
