import React from "react";
import { connect } from "react-redux";
import AnalyzingGif from "assets/img/loading.gif";
import { deleteCaseRequest } from "pages/Cases/redux/actions";
import { selectCases, selectPolling } from "pages/Cases/redux/selector";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import Button from "components/Button";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Analyzing = ({
  message,
  cases,
  polling,
  deleteCase,
}: {
  message?: string;
  cases: any;
  polling: boolean;
  deleteCase: any;
}) => {
  const { t } = useTranslation();

  if (!polling) {
    return null;
  }

  const handleCanel = () => deleteCase({ payload: { id: cases[0].id } }); // todo might be not the exact case you might want to delete

  return (
    <div className={styles.analyzing}>
      <img src={AnalyzingGif} />
      <h2>{t("pages.cases.analyzing.process")}</h2>
      <p>{t("pages.cases.analyzing.time")}</p>
      <Button variant={"default"} size={"small"} onClick={handleCanel}>
        {t("pages.cases.analyzing.cancel")}
      </Button>
      <div className={styles.ext} />
      {message && <span>{message}</span>}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  polling: selectPolling,
  cases: selectCases,
});

const withConnect = connect(mapStateToProps, {
  deleteCase: deleteCaseRequest,
});

export default compose(withConnect)(Analyzing);
