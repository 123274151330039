import createAction from "utils/createAction";

import * as ACTION_TYPE from "./constants";

export const authenticateRequest = createAction(
  ACTION_TYPE.AUTHENTICATE_REQUEST
);

export const authenticateSuccess = createAction(
  ACTION_TYPE.AUTHENTICATE_SUCCESS
);

export const authenticateFailure = createAction(
  ACTION_TYPE.AUTHENTICATE_FAILURE
);

export const validateEmailRequest = createAction(
  ACTION_TYPE.VALIDATE_EMAIL_REQUEST
);

export const validateEmailSuccess = createAction(
  ACTION_TYPE.VALIDATE_EMAIL_SUCCESS
);

export const validateEmailFailure = createAction(
  ACTION_TYPE.VALIDATE_EMAIL_FAILURE
);

export const registerRequest = createAction(ACTION_TYPE.REGISTER_REQUEST);

export const registerSuccess = createAction(ACTION_TYPE.REGISTER_SUCCESS);

export const registerFailure = createAction(ACTION_TYPE.REGISTER_FAILURE);
