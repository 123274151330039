import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import classnames from "classnames";
import { shiftToNearestNonEmptyPolygons } from "@remedylogic/rai-canvas-craft";
import Content from "./components/Content";
import Info from "./components/Info";
import Folders from "./components/Folders";
import Sidebar from "./components/Sidebar";
import Tabs from "./components/Tabs";
import Toolbar from "./components/Toolbar";
import Feedback from "./components/Feedback";
import {
  selectPendingFeedbackData,
  selectFeedbackData,
  selectFeedbackLoader,
} from "./selector";
import {
  setPendingFeedbackRequest,
  setFeedbackRequest,
  getFeedbackRequest,
  postFeedbackRequest,
} from "./actions";
import { preloadData } from "./utils/preloadData";
import styles from "./index.module.scss";

const configuration = {
  FEEDBACK_FIELDS: {
    pathology: {
      pathology: {
        type: "select",
        options: {
          spondylolisthesis: "spondylolisthesis",
          modic: "modic",
          dehydration: "dehydration",
          osteophyte: "osteophyte",
          schmorlNode: "schmorl node",
          vertebralBodyHeightLoss: "vertebral body height loss",
          discHeightLoss: "disc height loss",
          transitionalVertebra: "transitional vertebra",
          extrusion: "herniation", // todo hardcode
          protrusion: "herniation", // todo hardcode
          bulging: "bulging",
          centralStenosis: "central stenosis",
          lateralRecessStenosis: "lateral recess stenosis",
          foraminalStenosis: "foraminal stenosis",
          arthrosis: "arthrosis",
          synovitis: "synovitis",
          hypertrophyOfLigamentumFlavum: "hypertrophy of ligamentum flavum",
          aortaAneurysm: "aortic aneurism",
        },
      },
    },
    level: {
      level: {
        type: "select",
        options: {
          t12: "T12",
          t12_l1: "T12/L1",
          l1: "L1",
          l1_l2: "L1/L2",
          l2: "L2",
          l2_l3: "L2/L3",
          l3: "L3",
          l3_l4: "L3/L4",
          l4: "L4",
          l4_l5: "L4/L5",
          l5: "L5",
          l5_s1: "L5/S1",
          s1: "S1",
          other: "other",
        },
      },
    },
    spondylolisthesis: {
      grade: {
        type: "select",
        options: {
          0: "absent",
          1: "grade I",
          2: "grade II",
          3: "grade III",
          4: "grade IV",
        },
      },
    },
    modic: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    dehydration: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    osteophyte: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    schmorlNode: {
      presence: {
        type: "select",
        options: { 0: "absent", 1: "present" },
      },
    },
    vertebralBodyHeightLoss: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    discHeightLoss: {
      severity: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    transitionalVertebra: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    extrusion: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    protrusion: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    herniation: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    bulging: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    centralStenosis: {
      severity: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    lateralRecessStenosis: {
      severity: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
      side: {
        type: "select",
        options: {
          bilateral: "bilateral",
          left: "left",
          right: "right",
        },
      },
    },
    foraminalStenosis: {
      severity: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
      side: {
        type: "select",
        options: {
          bilateral: "bilateral",
          left: "left",
          right: "right",
        },
      },
    },
    arthrosis: {
      severity: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    synovitis: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    hypertrophyOfLigamentumFlavum: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
    aortaAneurysm: {
      presence: {
        type: "select",
        options: {
          0: "absent",
          1: "present",
          2: "present",
          3: "present",
          4: "present",
        },
      },
    },
  },
  MRI_VIEWER_CONFIG: {
    bodyPartsAvailable: ["lumbar"],
    defaultViewMode: "all",
    defaultSegmentationOpacity: 0.5,
    defaultClippingLineOpacity: 1,
    defaultMeasurementsVisibility: true,
    property: "seriesUID",
    maxTabs: 3,
    figureDim: 0.85,
    textColor: "#fff",
    fontSize: 24,
    fontFamily: "Arial",
    lineWidth: 1,
    lineColor: "#78ff78",
    triangleColor: "#78ff78",
    triangleSize: 7,
    triangleRotated: true,
    clippingLineWidth: 2,
    angleLineWidth: 1,
    angleLineColor: "yellow",
    indicatingLineLength: 200,
    indicatingLineWidth: 2,
    indicatingLineEdgeColor: "transparent",
    indicatingLineMiddleColor: "rgba(0, 255, 55, 0.5)",
    indicatingLineOffset: 0,
    labelBackgroundColor: "rgba(0, 255, 55, 0.5)",
    labelBorderColor: "#78ff78",
    labelBorderRadius: 4,
    labelPadding: 7,
    labelEdgeSafePadding: 40,
    labelFontSize: 24,
    labelFontFamily: "Arial",
    polygonLineColor: "rgba(0, 255, 55, 0.75)",
    polygonFillColor: "transparent",
    polygonWidth: 3,
    polygonTolerance: 0.75,
    pathologies: {
      spondylolisthesis: {
        "1": "grade I",
        "2": "grade II",
        "3": "grade III",
        "4": "grade IV",
      },
      centralStenosis: {
        "1": "mild",
        "2": "moderate",
        "3": "severe",
      },
      lateralRecessStenosis: {
        "1": "mild",
        "2": "moderate",
        "3": "severe",
      },
      foraminalStenosis: {
        "1": "mild",
        "2": "moderate",
        "3": "severe",
      },
    },
  },
};

type Nullable<T> = T | null;

const MRIViewer = ({
  researchId = null,
  auth = null,
  activeFile = null,
  analysisData = {},
  pathologiesList,
  onClose,
  feedback,
  getFeedback,
  postFeedback,
  feedbackLoader,
  pendingFeedback,
  setPendingFeedback,
  setFeedback,
}: {
  researchId: string | null;
  auth: string | null;
  activeFile?: Nullable<number>;
  analysisData: any;
  pathologiesList: any;
  pathologies?: Array<string>;
  onClose?: (arg?: boolean) => void;
  feedback: Array<any>;
  getFeedback: (params: any) => void;
  postFeedback: (feedback: any) => void;
  feedbackLoader: boolean | null;
  pendingFeedback: any;
  setPendingFeedback: (feedback: any) => void;
  setFeedback: () => void;
}) => {
  const [loaded, setLoaded] = useState(false as boolean);
  const [figures, setFigures] = useState([] as any);
  const [size, setSize] = useState([0, 0]);
  const [tabsKeys, setTabsKeys] = useState([] as any);
  const [dragging, setDragging] = useState(null as any);
  const [viewMode, setViewMode] = useState(
    configuration.MRI_VIEWER_CONFIG.defaultViewMode
  );
  const [presentation, setPresentation] = useState(false);
  const [segmentationOpacity, setSegmentationOpacity] = useState(
    configuration.MRI_VIEWER_CONFIG.defaultSegmentationOpacity * 100
  );
  const [clippingLinesOpacity, setClippingLinesOpacity] = useState(
    configuration.MRI_VIEWER_CONFIG.defaultClippingLineOpacity * 100
  );
  const [measurementsVisibility, setMeasurementsVisibility] = useState(
    configuration.MRI_VIEWER_CONFIG.defaultMeasurementsVisibility
  );
  const [sidebarFolded, setSidebarFolded] = useState(false as boolean);
  // const [infoFolded, setInfoFolded] = useState(false as boolean);

  const tabsRef = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    !loaded && setLoaded(true);
    const getData = async () => {
      const data = await preloadData({ files: analysisData.files, auth });
      setFigures(data);
    };
    !figures.length && getData();
    !tabsKeys.length && setSidebarFolded(false);
    setTimeout(() => handleResize(), 0);
  }, [tabsKeys, figures, pendingFeedback, viewMode]);

  useEffect(() => {
    (!tabsKeys.length ||
      !tabsKeys.find((tk: any) =>
        tk.includes(pendingFeedback?.SOPInstanceUID)
      )) &&
      setPendingFeedback(null);
  }, [tabsKeys]);

  useEffect(() => {
    if (
      researchId &&
      feedback &&
      feedback.length &&
      feedback[0].researchId !== researchId
    ) {
      setFeedback();
    } else {
      !feedback && getFeedback({ researchId });
    }
  }, [feedback, researchId]);

  useEffect(() => {
    figures &&
      activeFile &&
      setTabsKeys(
        folders
          .map(
            (folder, index) =>
              `${configuration.MRI_VIEWER_CONFIG.property}:${folder[0].projection}.${folder[0].SOPInstanceUID}.${index}`
          )
          .reverse()
      );
  }, [figures]);

  const handleResize = () =>
    setSize([tabsRef?.current?.clientWidth, tabsRef?.current?.clientHeight]);

  if (
    !researchId ||
    !auth ||
    !analysisData ||
    !analysisData.files ||
    !analysisData.files.length
  ) {
    return null;
  }

  const handlePostFeedback = ({ name, value, levelId, SOPInstanceUID }: any) =>
    postFeedback({ name, value, levelId, SOPInstanceUID, researchId });

  const handleClose = () => {
    if (!onClose) {
      return null;
    }
    document.body.style.overflow = "initial";
    document.body.style.userSelect = "initial";
    setLoaded(false);
    setTimeout(() => onClose(true), 250);
  };

  const handleSetTabsKeys = ({
    key,
    target,
    index,
  }: {
    key: string;
    target?: string;
    index?: number;
  }) => {
    let keys = [...(tabsKeys as Array<string>)];
    if (keys.includes(key)) {
      keys = keys.filter((tab: string) => tab !== key);
    } else if (target === "tab" && index !== undefined) {
      keys[index] = key;
    } else {
      keys.splice(index ?? keys.length, 0, key);
    }
    setTabsKeys(keys);
    setTimeout(() => handleResize(), 0);
  };

  const handleSidebarFold = () => {
    setSidebarFolded(!sidebarFolded);
    setTimeout(() => handleResize(), 0);
  };

  const folders = [
    // @ts-ignore ¯\_(ツ)_/¯
    ...new Set([
      ...figures.map(
        (figure: any) => figure[configuration.MRI_VIEWER_CONFIG.property]
      ),
    ]),
  ]
    .filter((t) => t !== undefined)
    .map((t) =>
      shiftToNearestNonEmptyPolygons(
        figures.filter(
          (figure: any) =>
            figure[configuration.MRI_VIEWER_CONFIG.property] === t ||
            figure?.projection === t
        )
      )
    );

  const tabs = tabsKeys.map((key: string) => ({
    key,
    figures: folders.find(
      (folder) =>
        key.includes(configuration.MRI_VIEWER_CONFIG.property) &&
        key.includes(folder[0].SOPInstanceUID)
    ),
  }));

  return (
    <div
      className={classnames(styles.MRIViewer, {
        [styles._integrated]: !onClose,
        [styles._loaded]: loaded,
      })}
    >
      {onClose && <div className={styles.overlay} onClick={handleClose} />}
      <div className={styles.container}>
        <Toolbar
          active={tabsKeys.length}
          sidebarFolded={sidebarFolded}
          handleSidebarFold={handleSidebarFold}
          viewMode={viewMode}
          setViewMode={setViewMode}
          presentation={presentation}
          setPresentation={setPresentation}
          segmentationOpacity={segmentationOpacity}
          clippingLinesOpacity={clippingLinesOpacity}
          measurementsVisibility={measurementsVisibility}
          setSegmentationOpacity={setSegmentationOpacity}
          setClippingLinesOpacity={setClippingLinesOpacity}
          setMeasurementsVisibility={setMeasurementsVisibility}
          pendingFeedback={pendingFeedback}
        />
        <Content loaded={!!figures.length && !!feedback}>
          {/* {!infoFolded && <Info onClose={() => setInfoFolded(true)} />} */}
          <Sidebar folded={sidebarFolded}>
            <Folders
              maxTabs={configuration.MRI_VIEWER_CONFIG.maxTabs}
              property={configuration.MRI_VIEWER_CONFIG.property}
              bodyPartsAvailable={
                configuration.MRI_VIEWER_CONFIG.bodyPartsAvailable
              }
              folders={folders}
              tabsKeys={tabsKeys}
              dragging={dragging}
              onSelectFolder={handleSetTabsKeys}
              onDragging={setDragging}
            />
          </Sidebar>
          <Tabs
            ref={tabsRef}
            maxTabs={configuration.MRI_VIEWER_CONFIG.maxTabs}
            tabs={tabs}
            size={size}
            analysisData={analysisData}
            onCloseTab={handleSetTabsKeys}
            onDrop={handleSetTabsKeys}
            viewMode={viewMode}
            presentation={presentation}
            segmentationOpacity={segmentationOpacity}
            clippingLinesOpacity={clippingLinesOpacity}
            measurementsVisibility={measurementsVisibility}
            activeFile={activeFile}
            dragging={dragging}
            feedback={feedback}
            pendingFeedback={pendingFeedback}
            setPendingFeedback={setPendingFeedback}
            postFeedback={handlePostFeedback}
            configuration={configuration}
            pathologiesList={pathologiesList}
          />
          <Feedback
            loader={feedbackLoader}
            viewMode={viewMode}
            feedback={feedback}
            analysisData={analysisData}
            pendingFeedback={pendingFeedback}
            setPendingFeedback={setPendingFeedback}
            postFeedback={handlePostFeedback}
            configuration={configuration}
          />
        </Content>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  pendingFeedback: selectPendingFeedbackData,
  feedback: selectFeedbackData,
  feedbackLoader: selectFeedbackLoader,
});

const withConnect = connect(mapStateToProps, {
  setPendingFeedback: setPendingFeedbackRequest,
  getFeedback: getFeedbackRequest,
  setFeedback: setFeedbackRequest,
  postFeedback: postFeedbackRequest,
});

export default compose(withConnect)(MRIViewer);
