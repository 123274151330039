import React, { useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Folder = ({
  draggable,
  disabled,
  index,
  folder,
  property,
  tabsKeys,
  dragging,
  handleSelectFolder,
  handleDragging,
}: {
  draggable: boolean;
  disabled: boolean;
  index: number;
  folder: any;
  property: string;
  tabsKeys: Array<string>;
  dragging: any;
  handleSelectFolder: ({
    key,
    target,
    index,
  }: {
    key: string;
    target?: string;
    index?: number;
  }) => void;
  handleDragging: (_: any) => void;
}) => {
  const { t } = useTranslation();
  const key = `${property}:${folder[0].projection}.${folder[0].SOPInstanceUID}.${index}`;
  return (
    <figure
      draggable={!disabled && !tabsKeys.includes(key) && draggable}
      className={classnames(styles.folder, {
        [styles._disabled]: disabled,
        [styles._active]: tabsKeys.includes(key),
        [styles._dragging]: dragging,
      })}
      onClick={() => !disabled && handleSelectFolder({ key })}
      onDragStart={() => !disabled && handleDragging({ key })}
      onDragEnd={() => !disabled && handleDragging(null)}
    >
      <span>
        <img src={folder[0].image.src} alt={folder[0][property]} />
        <label>{`${folder.length} ${t(
          "components.MRIViewer.folders.slices"
        )}`}</label>
      </span>
      <figcaption>
        {folder[0].projection.toUpperCase()}{" "}
        {!!folder[0].bodyPart && (
          <>[{t(`components.MRIViewer.folders.${folder[0].bodyPart}`)}]</>
        )}
      </figcaption>
    </figure>
  );
};

const Folders = ({
  maxTabs,
  property,
  bodyPartsAvailable,
  folders,
  tabsKeys,
  dragging,
  onSelectFolder,
  onDragging,
}: {
  maxTabs: number;
  property: string;
  folders: Array<{}>;
  bodyPartsAvailable: Array<string>;
  tabsKeys: Array<string>;
  dragging: any;
  onSelectFolder: ({
    key,
    target,
    index,
  }: {
    key: string;
    target?: string;
    index?: number;
  }) => void;
  onDragging: (_: any) => void;
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleSelectFolder = ({
    key,
    target,
    index,
  }: {
    key: string;
    target?: string;
    index?: number;
  }) => {
    if (tabsKeys.includes(key) || tabsKeys.length < maxTabs) {
      return onSelectFolder({ key, target, index });
    }
    //TODO: add translation
    setError(
      `Oops! You can use only ${maxTabs} tab${
        maxTabs > 1 ? "s" : ""
      } at a time.`
    ); // add as more clippingLines colors as more tabs you'd like to use at a time and change the `maxTabs` value accordingly
    return setTimeout(() => setError(null), 5000); // change that to whatever outside message handler you'd like to use instead
  };

  return (
    <div className={styles.folders}>
      {error && <div className={styles.error}>{error}</div>}
      {folders && folders.length ? (
        folders
          .sort(
            (a, b) =>
              (bodyPartsAvailable.includes(b[0].bodyPart) ? 1 : 0) -
              (bodyPartsAvailable.includes(a[0].bodyPart) ? 1 : 0)
          )
          .map((folder: any, key) => (
            <Folder
              key={key}
              draggable={true}
              disabled={!bodyPartsAvailable.includes(folder[0].bodyPart)}
              index={key}
              folder={folder}
              property={property}
              tabsKeys={tabsKeys}
              dragging={dragging}
              handleSelectFolder={handleSelectFolder}
              handleDragging={onDragging}
            />
          ))
      ) : (
        <div className={styles.placeholder}>
          {t("components.MRIViewer.folders.no-folders")}
        </div>
      )}
      <i />
    </div>
  );
};

export default Folders;
