import { createSelector } from "reselect";

export const selectCases = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("list")
);

export const selectPolling = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("polling")
);

export const selectGenerating = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("generating").toJS()
);

export const selectDeleting = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("deleting").toJS()
);

export const selectRetrying = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("retrying").toJS()
);

export const selectAssets = createSelector(
  [(state: any) => state.cases, (ownProps: any) => ownProps],
  (state) => state && state.get("assets").toJS()
);
