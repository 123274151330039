import { all, put, select, takeLatest } from "redux-saga/effects";

import { closeModal } from "components/Flows/Modal/actions";
import { toast } from "components/Flows/Toast/core";

import {
  // clearUpload, // todo remove comment here sometime when UI change to accept multiple studies to analyze
  setLoadingGlobalUploadFileRequest,
} from "./actions";
import { FINISH_UPLOADING_FILE_REQUEST } from "./constants";
import { selectUploaderStatus } from "./selector";

function* calcFullStatus() {
  try {
    const statuses = yield select(selectUploaderStatus);
    yield put(setLoadingGlobalUploadFileRequest(true));
    const statusesJS = statuses.toJS();
    const allStatuses = Object.keys(statusesJS).map((key) => statusesJS[key]);
    if (allStatuses.every((value) => value === "success")) {
      yield put(closeModal({ id: "uploader" }));
      // yield put(clearUpload()) // todo remove comment here sometime when UI change to accept multiple studies to analyze
    }
    if (allStatuses.includes("loading")) {
      yield put(setLoadingGlobalUploadFileRequest(true));
    } else {
      yield put(setLoadingGlobalUploadFileRequest(false));
    }
  } catch (error) {
    toast.success(
      { title: "Failed to upload MRI study" },
      { autoClose: 5000, type: "error" }
    );
  }
}

function* Saga() {
  yield all([takeLatest(FINISH_UPLOADING_FILE_REQUEST, calcFullStatus)]);
}

export default Saga;
