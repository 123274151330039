import { createSelector } from "reselect";

export const selectValidation = createSelector(
  [(state: any) => state.auth, (ownProps: any) => ownProps],
  (state) => state && state.get("validation")
);

export const selectUser = createSelector(
  [(state: any) => state.auth, (ownProps: any) => ownProps],
  (state) => state && state.get("user")
);
