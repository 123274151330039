import StartPage from "./index";

const localizedStartRoutes = (lang: string) => [
  {
    exact: true,
    path: `/${lang}/start`,
    component: StartPage,
  },
];

export default localizedStartRoutes;
