import axios from "axios";

export const preloadData = async ({
  files,
  auth,
}: {
  files: Array<any>;
  auth?: string | null;
}) => {
  if (!auth) return files;

  const getData = async (path: string) => {
    const { data } = await axios.get(path);
    return data;
  };

  const {
    data: { objects },
  } = await axios.post(
    `//${process.env.REACT_APP_API}/v1/file/getSignedUrls`,
    {
      keys: ["annotationsPath", "measurementsPath", "parsedPath"],
      objects: files.filter(
        (file: any) =>
          file.projection ||
          file.projection === "tra" ||
          file.projection === "sag"
      ),
    },
    { headers: { "x-auth-email": auth } }
  );

  return await Promise.all(
    objects.map(async (file: any) => {
      try {
        const image: HTMLImageElement = new window.Image();
        image.src = file.parsedPath;
        await image.decode();
        return {
          ...file,
          areaMeasurementsPolygons: file.measurementsPath
            ? await getData(file.measurementsPath)
            : null,
          annotationsPolygons: file.annotationsPath
            ? await getData(file.annotationsPath)
            : null,
          image,
        };
      } catch {
        throw new Error(`Failed to load data for ${file.SOPInstanceUID}`);
      }
    })
  );
};
