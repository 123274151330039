import React from "react";
import { connect } from "react-redux";
import classes from "assets/scss/style.module.scss";
import cn from "classnames";
import { createStructuredSelector } from "reselect";

import {
  makeSelectProgressUploadFile,
  makeSelectStatusUploadFile,
} from "./redux/selector";
import ProgressBar from "./ProgressBar";

export const getStatusTitle = (value: number, status: string) => {
  switch (true) {
    case value === -1 && status === "error":
      return "Incorrect file type";
    case value === 100 && status === "success":
      return "Completed";
    case value >= 0 && value < 100 && status === "loading":
      return `${value}%`;
    default:
      return `${value}%`;
  }
};

export const getStatusFiller = (value: number, status: string) => {
  switch (true) {
    case value === -1 && status === "error":
      return "rgba(255, 53, 53, 0.64)";
    case value === 100 && status === "success":
      return "rgba(64, 192, 87, 0.64)";
    case value >= 0 && value < 100 && status === "loading":
      return "rgba(34, 139, 230, 0.64)";

    default:
      return "rgba(34, 139, 230, 0.64)";
  }
};

const FileItem = (props: any) => {
  const { file, name, value = 0, status } = props;
  const finished = value === 100 && status === "success";
  const error = value === -1 && status === "error";

  const progressfillerStyles = {
    width: `${Number(value)}%`,
    backgroundColor: getStatusFiller(value, status),
  };

  return (
    <div
      className={cn(
        classes.UploadFileListContainerItem,
        finished && classes.UploadFileListContainerFinished,
        error && classes.UploadFileListContainerError
      )}
    >
      <i className={classes.iconFileType} />
      <div className={classes.UploadFileListContainerGrid}>
        <div className={classes.UploadFileListContainerItemTitle}>
          {name}
          <div className={classes.UploadFileListContainerItemTitleIcons}>
            {status !== "loading" && (
              <i
                onClick={() => props?.onDelete(file)}
                className={classes.iconFileActionClose}
              />
            )}
          </div>
        </div>
        <ProgressBar progressfillerStyles={progressfillerStyles} />
        <div className={classes.UploadFileListContainerItemDescription}>
          {getStatusTitle(value, status)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  status: makeSelectStatusUploadFile,
  value: makeSelectProgressUploadFile,
});

export default connect(mapStateToProps)(FileItem);
