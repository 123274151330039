import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Nothing from "components/Nothing";

import Catch from "./сatch";

type Props = {
  children: ReactNode;
};

const MyErrorBoundary = Catch(({ children }: Props, error?: Error) => {
  //TODO: add some solution here
  // const { t } = useTranslation();

  return (
    <>
      {error ? (
        <Nothing
          title={"500"}
          description={error?.message ?? "Something went wrong."}
          actions={<a href={"/"}>🏠 Go back to square one</a>}
        />
      ) : (
        children
      )}
    </>
  );
});

export default MyErrorBoundary as any;
