import React from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

const Button = ({
  children,
  variant = "initial",
  size = "initial",
  className,
  type = "button",
  badge,
  onClick,
  href,
  target,
  rel,
  ...rest
}: {
  variant?: string;
  size?: string;
  className?: any;
  children?: any;
  type?: "submit" | "reset" | "button" | undefined;
  style?: any;
  badge?: any;
  disabled?: boolean;
  onClick?: (e: any) => void;
  href?: string;
  target?: string;
  rel?: string;
}) =>
  href ? (
    <a
      className={classnames(
        styles.button,
        { [styles._nonClickable]: !onClick && type !== "submit" },
        `${styles[`_${size}`]}`,
        `${styles[`_${variant}`]}`,
        className
      )}
      onClick={onClick}
      href={href}
      target={target}
      rel={rel}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <button
      type={type}
      className={classnames(
        styles.button,
        { [styles._nonClickable]: !onClick && type !== "submit" },
        `${styles[`_${size}`]}`,
        `${styles[`_${variant}`]}`,
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
      {badge && <span className={styles.badge}>{badge}</span>}
    </button>
  );

export const ButtonGroup = ({
  children,
  className,
}: {
  children?: any;
  className?: any;
}) => (
  <div className={classnames(styles.buttonGroup, className)}>{children}</div>
);

export const ButtonRow = ({
  children,
  className,
}: {
  children?: any;
  className?: any;
}) => <div className={classnames(styles.buttonRow, className)}>{children}</div>;

export const ButtonLoading = ({
  isLoading = false,
  children,
  className,
}: {
  isLoading?: boolean;
  children?: any;
  className?: any;
}) => (
  <div className={classnames(styles.buttonLoading, className)}>
    {isLoading ? <span /> : children}
  </div>
);

export default Button;
