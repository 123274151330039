import { fromJS } from "immutable";
import { AnyAction } from "redux";

import { CLOSE_MODAL, OPEN_MODAL } from "./constants";

const initialState = fromJS({}) as any;

const reducer = (state = initialState, { type, id, props }: AnyAction) => {
  switch (type) {
    case OPEN_MODAL: {
      return state.setIn([id, "open"], true).setIn([id, "props"], props);
    }

    case CLOSE_MODAL: {
      return state.setIn([id, "open"], false);
    }

    default:
      return state;
  }
};

export default reducer;
