import { request } from "api";
import { Services } from "constants/services";

class ClassAPI {
  info = (headers: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/user/info`,
      method: "GET",
      headers,
    });

  validate = (data: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/user/validate`,
      method: "POST",
      data,
    });

  register = (data: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/user/register`,
      method: "POST",
      data,
    });

  update = (data: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/user/update`,
      method: "PUT",
      data,
    });
}

const API = new ClassAPI();

export default API;
