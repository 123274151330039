import React, {
  Children,
  cloneElement,
  DragEvent,
  FC,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

interface IDropSpace {
  target: string;
  index: number;
  onDrop: ({
    key,
    target,
    index,
  }: {
    key: string;
    target: string;
    index: number;
  }) => void;
  dragging: any;
  style?: any;
  children?: ReactNode;
  rest?: any;
}

const DropSpace: FC<IDropSpace> = ({
  target,
  index,
  onDrop,
  dragging,
  style,
  children = <></>,
  ...rest
}) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const handleDropEvent = ({
    e,
    type,
  }: {
    e: DragEvent<HTMLInputElement>;
    type?: string;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "over") {
      return null;
    }

    setHovered(type === "enter");

    type === "drop" &&
      target &&
      index !== undefined &&
      onDrop &&
      onDrop({ ...dragging, target, index });
  };

  if (!children || Children.count(children) < 1) {
    return null;
  }

  const child = Children.toArray(children)[0] as ReactElement<any>;

  return cloneElement(child, {
    ...child.props,
    onDragOver: (e: DragEvent<HTMLInputElement>) =>
      handleDropEvent({ e, type: "over" }),
    onDragEnter: (e: DragEvent<HTMLInputElement>) =>
      handleDropEvent({ e, type: "enter" }),
    onDragLeave: (e: DragEvent<HTMLInputElement>) =>
      handleDropEvent({ e, type: "leave" }),
    onDrop: (e: DragEvent<HTMLInputElement>) =>
      handleDropEvent({ e, type: "drop" }),
    className: classnames(styles.dropSpace, child.props.className, {
      [styles._dragging]: dragging,
      [styles._hovered]: hovered,
    }),
    style,
    ...rest,
  });
};

export default DropSpace;
