import { push } from "connected-react-router/immutable";
import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  closeFlowLoader,
  openFlowLoader,
} from "components/Flows/Loader/actions";
import { toast } from "components/Flows/Toast/core";
import { closeShelf } from "components/Shelf/actions";
import { clearUpload } from "components/Uploader/redux/actions";

import API from "../api";

import * as actions from "./actions";
import {
  ANALYZE_REQUEST,
  DELETE_CASE_REQUEST,
  GENERATE_ASSET_REQUEST,
  GET_CASES_LOADER,
  GET_CASES_REQUEST,
  RETRY_ANALYSIS_REQUEST,
} from "./constants";

function* getCases({ payload: { _silent = false } }: any) {
  try {
    yield !_silent && put(openFlowLoader({ id: GET_CASES_LOADER }));
    let polling = true;
    while (polling) {
      const cases = yield call(API.getCases);
      polling =
        cases &&
        cases.length &&
        (!cases[cases.length - 1].dicom_report_available ||
          !cases[cases.length - 1].pdf_report_available ||
          !["complete", "error", "fail"].some((status) =>
            cases[cases.length - 1].status?.toLowerCase().includes(status)
          ));
      yield cases &&
        put(
          actions.getCasesSucces({
            payload: {
              list: cases.sort((a: any, b: any) => b.id - a.id),
              polling,
            },
          })
        );
      yield put(closeFlowLoader({ id: GET_CASES_LOADER }));
      yield polling && delay(5000);
    }
    yield put(closeShelf({ id: "new_study_shelf" }));
  } catch (error) {
    yield put(closeFlowLoader({ id: GET_CASES_LOADER }));
    console.error(error);
    toast.success(
      { title: "Failed to get cases" },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.getCasesFailure({ payload: error }));
  }
}

function* deleteCase({ payload }: any) {
  try {
    const { id } = payload;
    yield put(
      actions.deleteCaseStatusUpdate({
        payload: { id, status: true },
      })
    );
    yield call(API.deleteCase, id);
    const cases = yield getCases({ payload: { _silent: true } });
    if (cases && !cases.length) {
      yield put(push("/start"));
    }
    yield put(
      actions.deleteCaseStatusUpdate({
        payload: { id, status: false },
      })
    );
    return yield put(actions.deleteCaseSuccess());
  } catch (error) {
    console.error(error);
    toast.success(
      { title: "Failed to delete the case" },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.deleteCaseFailure({ payload: error }));
  }
}

function* analyze({ payload }: any) {
  try {
    const { id, recaptcha } = payload;
    yield call(API.analyze, { ...payload, "g-recaptcha-response": recaptcha });
    yield put(actions.analyzeSuccess({ payload: { polling: true } }));
    yield put(
      actions.retryAnalysisStatusUpdate({
        payload: { id, status: false },
      })
    );
    yield put(clearUpload());
    yield getCases({ payload: { _silent: true } });
    return yield put(push("/"));
  } catch (error) {
    console.error(error);
    toast.success(
      { title: "Failed to run the analysis" },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.analyzeFailure({ payload: error }));
  }
}

function* reanalyze({ payload }: any) {
  try {
    const { id, recaptcha } = payload;
    yield call(API.reanalyze, {
      ...payload,
      "g-recaptcha-response": recaptcha,
    });
    yield put(actions.analyzeSuccess({ payload: { polling: true } }));
    yield put(
      actions.retryAnalysisStatusUpdate({
        payload: { id, status: false },
      })
    );
    yield put(clearUpload());
    yield getCases({ payload: { _silent: true } });
    return yield put(push("/"));
  } catch (error) {
    console.error(error);
    toast.success(
      { title: "Failed to run the analysis" },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.analyzeFailure({ payload: error }));
  }
}

function* retryAnalysis({ payload }: any) {
  try {
    const { id } = payload;
    yield put(
      actions.retryAnalysisStatusUpdate({
        payload: { id, status: true },
      })
    );
    yield reanalyze({ payload });
    yield getCases({ payload: { _silent: true } });
    yield put(
      actions.retryAnalysisStatusUpdate({
        payload: { id, status: false },
      })
    );
    return yield put(
      actions.retryAnalysisSuccess({ payload: { polling: true } })
    );
  } catch (error) {
    console.error(error);
    toast.success(
      { title: "Failed to retry the analysis" },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.retryAnalysisFailure({ payload: error }));
  }
}

function* generateAsset({ payload: { assetType, id, intent } }: any) {
  try {
    if (intent === "download") {
      yield put(
        actions.generateAssetStatusUpdate({
          payload: { assetType, id, status: true },
        })
      );
    }

    const asset = yield call(API.generateAsset, assetType, id, intent);

    if (intent === "download") {
      asset?.url && (window.location.href = asset.url);
      yield put(
        actions.generateAssetStatusUpdate({
          payload: { assetType, id, status: false },
        })
      );
      if (
        !asset ||
        Object.getOwnPropertyNames(asset).length === 0 ||
        !asset.url
      ) {
        toast.success(
          { title: `Failed to download a ${assetType}` },
          { autoClose: 5000, type: "error" }
        );
      }
    }

    if (intent === "view") {
      if (asset?.url) {
        yield put(
          actions.generateAssetSuccess({
            payload: { assetType, id, url: asset.url },
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
    toast.success(
      { title: `Failed to download a ${assetType}` },
      { autoClose: 5000, type: "error" }
    );
    return yield put(actions.generateAssetFailure({ payload: error }));
  }
}

function* Saga() {
  yield all([takeLatest(GET_CASES_REQUEST, getCases)]);
  yield all([takeLatest(DELETE_CASE_REQUEST, deleteCase)]);
  yield all([takeLatest(ANALYZE_REQUEST, analyze)]);
  yield all([takeLatest(RETRY_ANALYSIS_REQUEST, retryAnalysis)]);
  yield all([takeEvery(GENERATE_ASSET_REQUEST, generateAsset)]);
}

export default Saga;
