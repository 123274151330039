import { request } from "api";
import { Services } from "constants/services";

class ClassAPI {
  getCases = () =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/list`,
      method: "GET",
    });

  deleteCase = (id: string) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/delete/${id}`,
      method: "DELETE",
    });

  analyze = (data: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/analyze`,
      method: "POST",
      data,
    });

  reanalyze = (data: any) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/reanalyze`,
      method: "POST",
      data,
    });

  generateAsset = (assetType: string, id: string, intent = "download") =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/${assetType}?researchId=${id}&type=${intent}`, // todo  someday could be also type=view or type=download, to either view it in UI of download as a file.
      method: "GET",
    });
}

const API = new ClassAPI();

export default API;
