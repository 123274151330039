import React from "react";
import { useTranslation } from "react-i18next";

import Nothing from "components/Nothing";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Nothing
      title={"404"}
      description={t("components.errors.not-found.404")}
      actions={<a href={"/"}>🏠 ${t("components.errors.not-found.action")}</a>}
    />
  );
};

export default NotFound;
