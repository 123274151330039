import { connectRouter } from "connected-react-router";
import { History, LocationState } from "history";
import storage from "localforage";
import casesReducer from "pages/Cases/redux/reducer";
import { AnyAction, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { persistReducer } from "redux-persist";
import immutableTransform from "redux-persist-transform-immutable";

import drawersReducer from "components/Drawer/reducer";
import flowLoaderReducer from "components/Flows/Loader/reducer";
import modalsReducer from "components/Flows/Modal/reducer";
import shelvesReducer from "components/Shelf/reducer";
import { RESET_STORE } from "components/StateManagement/constants";
import uploaderReducer from "components/Uploader/redux/reducer";
import authReducer from "modules/Auth/redux/reducer";
import infoReducer from "modules/Info/redux/reducer";
import mriviewerReducer from "components/MRIViewer/reducer";

type TPersistConfig = {
  transforms: any;
  key: string;
  whitelist: string[];
  storage: any;
};

const rootPersistConfig: TPersistConfig = {
  transforms: [immutableTransform()],
  key: "remedylogic-radiologyai-1",
  whitelist: ["auth"],
  storage,
};

export const appReducer = (history: History<LocationState>) =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      router: connectRouter(history),
      form: formReducer,
      loader: flowLoaderReducer,
      modals: modalsReducer,
      drawers: drawersReducer,
      shelves: shelvesReducer,
      uploader: uploaderReducer,
      auth: authReducer,
      cases: casesReducer,
      info: infoReducer,
      mriviewer: mriviewerReducer,
    })
  );

export const rootReducer =
  (reducer: any) => (state: any, action: AnyAction) => {
    localStorage.getItem("logger") === "true" &&
      console.info(JSON.parse(JSON.stringify(state)));
    if (action.type === RESET_STORE) {
      storage.removeItem("persist:remedylogic-radiologyai-1").then(() => {
        state = undefined;
      });
    }
    return reducer(state, action);
  };
