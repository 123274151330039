import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import AnalyzeArrowIcon from "assets/icons/analyze-arrow.svg";
import ShieldIcon from "assets/icons/shield.svg";
import UploadIcon from "assets/icons/upload.svg";
import { analyzeRequest } from "pages/Cases/redux/actions";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import Button, { ButtonRow } from "components/Button";
import Input from "components/Input";
import UploaderModal from "components/Uploader/Modal";
import { clearUpload } from "components/Uploader/redux/actions";
import { selectUploaderGlobal } from "components/Uploader/redux/selector";
import {
  registerRequest,
  validateEmailRequest,
} from "modules/Auth/redux/actions";
import { selectUser, selectValidation } from "modules/Auth/redux/selector";
import { validateEmailFormat } from "utils/validates";
import styles from "./index.module.scss";
import { useLanguage } from "context/languageContext";

const StartAnalysis = ({
  user,
  uploaderGlobal,
  clearUpload,
  validation,
  validateEmail,
  register,
  analyze,
}: {
  user: any;
  uploaderGlobal: any;
  clearUpload: any;
  validation: any;
  validateEmail: any;
  register: any;
  analyze: any;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  const [fields, setFields] = useState<{
    full_name: string;
    email: string;
    organization: string;
  }>({
    full_name: user?.full_name || "",
    email: user?.email || "",
    organization: user?.organization || "",
  });
  const [recaptcha, setRecaptcha] = useState<null | string>(null);

  useEffect(() => setFields(user), [user]);

  useEffect(() => !user && validateEmail({ payload: fields?.email }), [fields]);

  const isEligableToUpload =
    (user || !validation?.email) &&
    fields?.full_name?.length &&
    validateEmailFormat(fields?.email) &&
    fields?.organization?.length;

  const isReadyToAnalyze = !uploaderGlobal.loading && uploaderGlobal.uploaded;

  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setFields((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleUploadMRIStudy = () => register({ payload: { fields, user } });

  const handleStartAnalysis = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { uploadPackage, remoteFolder } = uploaderGlobal;
    recaptcha &&
      analyze({
        payload: { uploadPackage, remoteFolder, recaptcha, language },
      });
  };

  return (
    <form onSubmit={handleStartAnalysis} className={styles.startAnalysis}>
      <Input
        required={true}
        label={t("pages.start.analazis.full-name")}
        name={"full_name"}
        value={fields?.full_name ?? ""}
        type={"text"}
        onChange={handleChange}
        placeholder={t("pages.start.analazis.full-name-placeholder")}
        error={validation?.full_name}
      />
      <Input
        readOnly={!!user}
        required={true}
        label={t("pages.start.analazis.email")}
        name={"email"}
        value={fields?.email ?? ""}
        type={"email"}
        onChange={handleChange}
        placeholder={t("pages.start.analazis.email-placeholder")}
        error={validation?.email}
      />
      <Input
        required={true}
        label={t("pages.start.analazis.organization")}
        name={"organization"}
        value={fields?.organization ?? ""}
        type={"text"}
        onChange={handleChange}
        placeholder={t("pages.start.analazis.organization-placeholder")}
        error={validation?.organization}
      />

      {isReadyToAnalyze ? (
        <ButtonRow>
          <Button
            size={"medium"}
            variant={"outline"}
            style={{ width: "fit-content" }}
            badge={uploaderGlobal.totalFiles}
            onClick={handleUploadMRIStudy}
          >
            {t("pages.start.analazis.selected-files")}
          </Button>
          <Button
            size={"medium"}
            variant={"simple"}
            style={{ width: "fit-content" }}
            onClick={clearUpload}
          >
            {t("pages.start.analazis.clear")}
          </Button>
        </ButtonRow>
      ) : (
        <Button
          disabled={!isEligableToUpload}
          size={"medium"}
          variant={"outline"}
          style={{ width: "fit-content" }}
          onClick={handleUploadMRIStudy}
        >
          {t("pages.start.analazis.upload-MRI")} <img src={UploadIcon} />
        </Button>
      )}

      {!!isReadyToAnalyze && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          onChange={(value: string) => setRecaptcha(value)}
          onExpired={() => setRecaptcha(null)}
        />
      )}

      <Button disabled={!isReadyToAnalyze || !recaptcha} type={"submit"}>
        {t("pages.start.analazis.start-analysis")}{" "}
        <img src={AnalyzeArrowIcon} />
      </Button>
      <div className={styles.note}>
        <img src={ShieldIcon} />
        <p>{t("pages.start.analazis.privacy")}</p>
      </div>
      <UploaderModal />
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  validation: selectValidation,
  user: selectUser,
  uploaderGlobal: selectUploaderGlobal,
});

const withConnect = connect(mapStateToProps, {
  clearUpload,
  validateEmail: validateEmailRequest,
  register: registerRequest,
  analyze: analyzeRequest,
});

export default compose(withConnect)(StartAnalysis);
