import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteCaseRequest } from "pages/Cases/redux/actions";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import Analyzing from "components/Analyzing";
import Cases from "components/Cases";
import { openModal } from "components/Flows/Modal/actions";
import Shelf from "components/Shelf";
import StartAnalysis from "components/StartAnalysis";

import { selectCases, selectPolling } from "./redux/selector";
import styles from "./index.module.scss";

const CasesPage = ({
  cases,
  polling,
  deleteCase,
}: {
  cases: any;
  polling: boolean;
  deleteCase: any;
}) => {
  const { t } = useTranslation();

  const handleCanel = () => deleteCase({ payload: { id: cases[0].id } }); // todo might be not the exact case you might want to delete

  if (!cases) {
    return <div />;
  }

  if (polling && cases?.length < 1) {
    return (
      <div className={styles.loading}>
        <div className={styles.container}>
          <svg
            className={styles.close}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleCanel}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9247 14.0003L21.7677 8.1572C22.2999 7.62502 22.2999 6.76482 21.7677 6.23263C21.2355 5.70045 20.3753 5.70045 19.8432 6.23263L14.0002 12.0758L8.15716 6.23263C7.62498 5.70045 6.7648 5.70045 6.23263 6.23263C5.70045 6.76482 5.70045 7.62502 6.23263 8.1572L12.0756 14.0003L6.23263 19.8435C5.70045 20.3756 5.70045 21.2358 6.23263 21.768C6.49803 22.0334 6.84646 22.1668 7.19489 22.1668C7.54332 22.1668 7.89175 22.0334 8.15716 21.768L14.0002 15.9249L19.8432 21.768C20.1086 22.0334 20.457 22.1668 20.8054 22.1668C21.1539 22.1668 21.5023 22.0334 21.7677 21.768C22.2999 21.2358 22.2999 20.3756 21.7677 19.8435L15.9247 14.0003Z"
            />
          </svg>
          <Analyzing message={t("pages.cases.analyzing.message")} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cases}>
      {cases && cases.length && <Cases />}
      <Shelf
        id={"new_study_shelf"}
        title={polling ? "" : t("pages.cases.analyzing.new-study")}
      >
        {cases[0].status.toLowerCase().includes("complete") ||
        cases[0].status.toLowerCase().includes("fail") ? (
          <StartAnalysis />
        ) : (
          <Analyzing />
        )}
      </Shelf>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  polling: selectPolling,
  cases: selectCases,
});

const withConnect = connect(mapStateToProps, {
  deleteCase: deleteCaseRequest,
  handleOpenModal: openModal,
});

export default compose(withConnect)(CasesPage);
