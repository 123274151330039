import ViewPage from "./index";

const localizedViewRoutes = (lang: string) => [
  {
    exact: true,
    path: `/${lang}/:id`,
    component: ViewPage,
  },
];

export default localizedViewRoutes;
