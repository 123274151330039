import * as React from "react";
import { IObject } from "types";

export type TModalActions = {
  id?: string;
  props?: IObject;
};

export type TModalHandler = ({ id, props }: TModalActions) => void;

export type TModal = {
  id: string;
  size?: "smallest" | "small" | "medium" | "big";
  handleCloseModal: TModalHandler;
  children?: any;
  isModalOpen?: boolean;
  title?: string;
  propsModal?: IObject;
  okText?: string;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: () => void;
  onOkDisable?: boolean;
};

export enum ModalSize {
  smallest = "20%",
  small = "30%",
  medium = "50%",
  big = "75%",
}
