import React, { useEffect, useLayoutEffect, useRef } from "react";

import { ToastPosition, ToastTransitionProps } from "../types";

import classes from "../../../../assets/scss/style.module.scss";

import { collapseToast } from "./collapseToast";
import { Default, SyntheticEvent } from "./constant";

export interface CSSTransitionProps {
  /**
   * Css class to apply when toast enter
   */
  enter: string;

  /**
   * Css class to apply when toast leave
   */
  exit: string;

  /**
   * Append current toast position to the classname.
   * If multiple classes are provided, only the last one will get the position
   * For instance `myclass--top-center`...
   * `Default: false`
   */
  appendPosition?: boolean;

  /**
   * Collapse toast smoothly when exit animation end
   * `Default: true`
   */
  collapse?: boolean;

  /**
   * Collapse transition duration
   * `Default: 300`
   */
  collapseDuration?: number;

  animationName: string;
}

const enum AnimationStep {
  Enter,
  Exit,
}

const bounceEnterClasses = (position: ToastPosition) => {
  switch (position) {
    case "top-left":
      return classes.ToastItemBounceEnterTopLeft;
    case "top-center":
      return classes.ToastItemBounceEnterTopCenter;
    case "top-right":
      return classes.ToastItemBounceEnterTopRight;
    case "bottom-left":
      return classes.ToastItemBounceEnterBottomLeft;
    case "bottom-center":
      return classes.ToastItemBounceEnterBottomCenter;
    case "bottom-right":
      return classes.ToastItemBounceEnterBottomRight;
    default:
      return classes.ToastItemBounceEnterTopCenter;
  }
};

const bounceExitClasses = (position: ToastPosition) => {
  switch (position) {
    case "top-left":
      return classes.ToastItemBounceExitTopLeft;
    case "top-center":
      return classes.ToastItemBounceExitTopCenter;
    case "top-right":
      return classes.ToastItemBounceExitTopRight;
    case "bottom-left":
      return classes.ToastItemBounceExitBottomLeft;
    case "bottom-center":
      return classes.ToastItemBounceExitBottomCenter;
    case "bottom-right":
      return classes.ToastItemBounceExitBottomRight;
    default:
      return classes.ToastItemBounceExitTopCenter;
  }
};

const slideEnterClasses = (position: ToastPosition) => {
  switch (position) {
    case "top-left":
      return classes.ToastItemSlideEnterTopLeft;
    case "top-center":
      return classes.ToastItemSlideEnterTopCenter;
    case "top-right":
      return classes.ToastItemSlideEnterTopRight;
    case "bottom-left":
      return classes.ToastItemSlideEnterBottomLeft;
    case "bottom-center":
      return classes.ToastItemSlideEnterBottomCenter;
    case "bottom-right":
      return classes.ToastItemSlideEnterBottomRight;
    default:
      return classes.ToastItemSlideEnterTopCenter;
  }
};

const slideExitClasses = (position: ToastPosition) => {
  switch (position) {
    case "top-left":
      return classes.ToastItemSlideExitTopLeft;
    case "top-center":
      return classes.ToastItemSlideExitTopCenter;
    case "top-right":
      return classes.ToastItemSlideExitTopRight;
    case "bottom-left":
      return classes.ToastItemSlideExitBottomLeft;
    case "bottom-center":
      return classes.ToastItemSlideExitBottomCenter;
    case "bottom-right":
      return classes.ToastItemSlideExitBottomRight;
    default:
      return classes.ToastItemSlideExitTopCenter;
  }
};

const getPosition = (type: string, position: ToastPosition, enter: boolean) => {
  switch (type) {
    case "Bounce":
      return enter ? bounceEnterClasses(position) : bounceExitClasses(position);
    case "Flip":
      return enter ? classes.ToastItemFlipEnter : classes.ToastItemFlipExit;
    case "Slide":
      return enter ? slideEnterClasses(position) : slideExitClasses(position);
    case "Zoom":
      return enter ? classes.ToastItemZoomEnter : classes.ToastItemZoomExit;
  }
};

/**
 * Css animation that just work.
 * You could use animate.css for instance
 *
 *
 * ```
 * cssTransition({
 *   enter: "animate__animated animate__bounceIn",
 *   exit: "animate__animated animate__bounceOut"
 * })
 * ```
 *
 */
export function cssTransition({
  animationName,
  enter,
  exit,
  appendPosition = false,
  collapse = true,
  collapseDuration = Default.COLLAPSE_DURATION,
}: CSSTransitionProps) {
  return function ToastTransition({
    children,
    position,
    preventExitTransition,
    done,
    nodeRef,
    isIn,
  }: ToastTransitionProps) {
    const enterClassName = appendPosition
      ? `${enter} ${getPosition(animationName, position, true)}`
      : enter;
    const exitClassName = appendPosition
      ? `${exit} ${getPosition(animationName, position, false)}`
      : exit;
    // const enterClassName = appendPosition ? `${enter}--${position}` : enter
    // const exitClassName = appendPosition ? `${exit}--${position}` : exit
    const animationStep = useRef(AnimationStep.Enter);

    useLayoutEffect(() => {
      const node = nodeRef.current!;
      const classToToken = enterClassName.split(" ");

      const onEntered = (e: AnimationEvent) => {
        if (e.target !== nodeRef.current) {
          return;
        }

        node.dispatchEvent(new Event(SyntheticEvent.ENTRANCE_ANIMATION_END));
        node.removeEventListener("animationend", onEntered);
        node.removeEventListener("animationcancel", onEntered);
        if (
          animationStep.current === AnimationStep.Enter &&
          e.type !== "animationcancel"
        ) {
          node.classList.remove(...classToToken);
        }
      };

      const onEnter = () => {
        node.classList.add(...classToToken);
        node.addEventListener("animationend", onEntered);
        node.addEventListener("animationcancel", onEntered);
      };

      onEnter();
    }, []);

    useEffect(() => {
      const node = nodeRef.current!;

      const onExited = () => {
        node.removeEventListener("animationend", onExited);
        collapse ? collapseToast(node, done, collapseDuration) : done();
      };

      const onExit = () => {
        animationStep.current = AnimationStep.Exit;
        node.className += ` ${exitClassName}`;
        node.addEventListener("animationend", onExited);
      };

      if (!isIn) {
        preventExitTransition ? onExited() : onExit();
      }
    }, [isIn]);

    return <>{children}</>;
  };
}
