import { fromJS } from "immutable";
import { IActionProps } from "types";

import {
  GET_PATHOLOGIES_LIST_REQUEST,
  GET_PATHOLOGIES_LIST_SUCCESS,
  GET_PATHOLOGIES_LIST_FAILURE,
} from "./constants";

const initialState = fromJS({
  info: null,
  error: null,
}) as any;

const info = (state = initialState, { type, payload }: IActionProps) => {
  switch (type) {
    case GET_PATHOLOGIES_LIST_REQUEST: {
      return state.set("error", null);
    }
    case GET_PATHOLOGIES_LIST_SUCCESS: {
      return state.set("pathologiesList", payload).set("error", null);
    }
    case GET_PATHOLOGIES_LIST_FAILURE: {
      return state.set("pathologiesList", null).set("error", payload);
    }
    default:
      return state;
  }
};

export default info;
