import { createSelector } from "reselect";

const selectModal = (state: any) => state.modals;

export const selectCurrentModal = createSelector(
  [selectModal, (state: any, ownProps: any) => ownProps.id],
  (modalState, id) => modalState.get(id)
);

export const makeSelectModalOpen = createSelector(
  selectCurrentModal,
  (state) => state && state.get("open")
);

export const makeSelectModalProps = createSelector(
  selectCurrentModal,
  (state) => state && state.get("props")
);
