import { request } from "api";
import { Services } from "constants/services";

class ClassAPI {
  getPathologies = () =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/info/pathologies`,
      method: "GET",
    });
}

const API = new ClassAPI();

export default API;
