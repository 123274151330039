export const slugValidate = (value?: string) =>
  value?.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g);

export const innValidate = (value?: string) =>
  value?.match(/^(\d{10}|\d{12})$/);

export const regChannelIdValidate = (value?: string) =>
  value?.match(/^[A-Z]{3}$/);

export function validateEmailFormat(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );
}

export const passwordIdValidate = (value?: string) =>
  value?.match(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
