import React from "react";
import NothingIllustration from "assets/img/nothing.svg";

import styles from "./index.module.scss";

const Nothing = ({
  title,
  description,
  image = NothingIllustration,
  actions,
}: {
  title?: string;
  description?: string;
  image?: any;
  actions?: any;
}) => (
  <div className={styles.nothing}>
    <img src={image} alt={title} width={300} />
    {title && <h3>{title}</h3>}
    {description && <label>{description}</label>}
    {actions && <div className={styles.actions}>{actions}</div>}
  </div>
);

export default Nothing;
