import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import API_GLOBAL from "api/api";

import { appReducer, rootReducer } from "../rootReducer";
import mySaga from "../rootSaga";

export const configureStore = (history: History) => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    API_GLOBAL.middleware,
    sagaMiddleware,
    routerMiddleware(history),
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const isProduction = process.env.NODE_ENV !== "production";

  const composeEnhancers =
    isProduction && typeof window === "object" && reduxDevtools
      ? reduxDevtools({ shouldHotReload: false })
      : compose;

  const store = createStore(
    rootReducer(appReducer(history)),
    {},
    composeEnhancers(...enhancers)
  );

  (store as any).runSaga = sagaMiddleware.run(mySaga);

  const persistor = persistStore(store);

  return { store, persistor };
};
