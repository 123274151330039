import { createSelector } from "reselect";

const selectUploaderState = (state: any) => state.uploader;
export const selectUploaderProgress = (state: any) =>
  state.uploader && state.uploader.get("progress");
export const selectUploaderStatus = (state: any) =>
  state.uploader && state.uploader.get("status");

export const selectUploaderGlobal = createSelector(
  [(state: any) => state.uploader, (ownProps: any) => ownProps],
  (state) => state && Object.fromEntries(state.get("global"))
);

export const selectUploaderKeys = (state: any) =>
  state.uploader && state.uploader.get("keys");

export const makeSelectUploaderFiles = () =>
  createSelector(selectUploaderState, (state) => state && state.get("files"));

export const makeSelectUploaderKeys = createSelector(
  selectUploaderState,
  (state) => state && state.get("keys")
);

export const makeSelectProgressUploadFile = createSelector(
  [
    selectUploaderProgress,
    (state: any, ownProps: any) => {
      return ownProps.hash;
    },
  ],
  (progressState, id) => progressState && progressState.get(id)
);

export const makeSelectStatusUploadFile = createSelector(
  [selectUploaderStatus, (state: any, ownProps: any) => ownProps.hash],
  (statusState, id) => statusState && statusState.get(id)
);

export const makeSelectKeysUploadFile = createSelector(
  selectUploaderState,
  (state) => state && state.get("keys") && state.get("keys").toJS()
);

export const makeSelectGlobalUploadFile = createSelector(
  selectUploaderState,
  (state) => state && state.get("global") && state.get("global").toJS()
);
