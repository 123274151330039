class ClassAPI {
  email = "";

  middleware =
    ({ getState }: any) =>
    (next: any) =>
    (action: any) => {
      const state = getState();
      this.email = state?.auth?.get("user")?.email;
      return next(action);
    };
}

const API_GLOBAL = new ClassAPI();

export default API_GLOBAL;
