import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { createStructuredSelector } from "reselect";

import { closeShelf, openShelf } from "./actions";
import styles from "./index.module.scss";
import { makeSelectShelfOpen, makeSelectShelfProps } from "./selector";

const Shelf = ({
  id,
  children,
  title,
  isShelfOpen,
  handleOpenShelf,
  handleCloseShelf,
}: {
  id: string;
  children?: any;
  title?: string;
  isShelfOpen: boolean;
  handleOpenShelf: any;
  handleCloseShelf: any;
}) => (
  <aside
    id={id}
    className={classnames(styles.shelf, { [styles._open]: !isShelfOpen })}
  >
    <div className={styles.content}>
      <svg
        className={styles.fold}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() =>
          isShelfOpen ? handleCloseShelf({ id }) : handleOpenShelf({ id })
        }
      >
        <path d="M15.4733 9.85248L11.9166 14.0002L15.4733 18.1478C15.7683 18.4759 15.72 18.9641 15.3667 19.2373C15.2108 19.358 15.0217 19.4168 14.8333 19.4168C14.595 19.4168 14.3583 19.3217 14.1933 19.1383L10.0266 14.4954C9.99409 14.459 9.97742 14.4164 9.95325 14.3762C9.93325 14.3437 9.90909 14.3158 9.89409 14.2802C9.85659 14.1913 9.83409 14.0976 9.83409 14.0032L9.83325 14.0001L9.83409 13.997C9.83409 13.9026 9.85659 13.809 9.89409 13.72C9.90909 13.6844 9.93325 13.6566 9.95325 13.6241C9.97742 13.5838 9.99409 13.5413 10.0266 13.5049L14.1933 8.862C14.4875 8.5339 15.0125 8.48902 15.3667 8.76295C15.72 9.03611 15.7683 9.52438 15.4733 9.85248Z" />
        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" />
      </svg>
      <h2>{title}</h2>
      {children}
    </div>
  </aside>
);

const mapStateToProps = createStructuredSelector({
  isShelfOpen: makeSelectShelfOpen,
  propsShelf: makeSelectShelfProps,
});

export default connect(mapStateToProps, {
  handleCloseShelf: closeShelf,
  handleOpenShelf: openShelf,
})(Shelf);
