import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { uploadFileRequest } from "../../modules/MRI/redux/actions";
import FlowModal from "../Flows/Modal";
import { closeModal } from "../Flows/Modal/actions";
import { makeSelectModalOpen } from "../Flows/Modal/selector";

import {
  makeSelectGlobalUploadFile,
  makeSelectUploaderFiles,
} from "./redux/selector";
import Uploader from "./index";
import { useTranslation } from "react-i18next";

const UploaderModal = ({ global, files }: any) => {
  const { t } = useTranslation();

  const [uploadTrigger, setUploadTrigger] = React.useState<boolean>(false);

  return (
    <FlowModal
      size="big"
      id="uploader"
      title={t("components.flow.modal.add-files")}
    >
      <Uploader
        global={global}
        offTrigger={() => setUploadTrigger(false)}
        uploadTrigger={uploadTrigger}
        setUploadTrigger={setUploadTrigger}
      />
    </FlowModal>
  );
};

const mapStateToProps = createStructuredSelector({
  global: makeSelectGlobalUploadFile,
  files: makeSelectUploaderFiles(),
});

export default connect(mapStateToProps, {
  upload: uploadFileRequest,
  isModalOpen: makeSelectModalOpen,
  close: closeModal,
})(UploaderModal);
