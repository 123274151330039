import createAction from "utils/createAction";

import * as ACTION_TYPE from "./constants";

export const getPathologiesListRequest = createAction(
  ACTION_TYPE.GET_PATHOLOGIES_LIST_REQUEST
);

export const getPathologiesListSucces = createAction(
  ACTION_TYPE.GET_PATHOLOGIES_LIST_SUCCESS
);

export const getPathologiesListFailure = createAction(
  ACTION_TYPE.GET_PATHOLOGIES_LIST_FAILURE
);
