import { request } from "api";
import { Services } from "constants/services";

class ClassAPI {
  getFeedback = ({ researchId }: { researchId: string }) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/feedback?researchId=${researchId}`,
      method: "GET",
    });

  postFeedback = ({
    name,
    value,
    levelId,
    SOPInstanceUID,
    researchId,
  }: {
    name: string;
    value: string;
    levelId: string;
    SOPInstanceUID: string;
    researchId: string;
  }) =>
    request({
      service: Services.REMEDY_CORE,
      url: `v1/research/feedback`,
      method: "POST",
      data: {
        name,
        value,
        levelId,
        SOPInstanceUID,
        researchId,
      },
    });
}

const API = new ClassAPI();

export default API;
