import { createSelector } from "reselect";

export const selectPendingFeedbackData = createSelector(
  [(state: any) => state.mriviewer, (ownProps: any) => ownProps],
  (state) => state && state.get("pendingFeedback")
);

export const selectFeedbackData = createSelector(
  [(state: any) => state.mriviewer, (ownProps: any) => ownProps],
  (state) => state && state.get("feedback")
);

export const selectFeedbackLoader = createSelector(
  [(state: any) => state.mriviewer, (ownProps: any) => ownProps],
  (state) => state && state.get("loader")
);
