import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { validateEmailFormat } from "utils/validates";

import styles from "./index.module.scss";

const Input = ({
  error,
  value,
  name,
  type,
  label,
  required,
  readOnly,
  disabled,
  className,
  onChange,
  ...rest
}: {
  error?: string | null;
  value: string;
  name: string;
  type: string;
  label: string;
  className?: any;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: ({ name, value }: { name: string; value: string }) => void;
}) => {
  const { t } = useTranslation();
  const [pristine, setPristine] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    error
  );

  useEffect(() => {
    if (required === true && !pristine && !value?.length) {
      return setErrorMessage(
        `${label.charAt(0).toUpperCase() + label.slice(1)} ` + `${t("components.input.required")}`
      );
    }
    if (type === "email" && !pristine && !validateEmailFormat(value)) {
      return setErrorMessage(t("components.input.bad-format"));
    }
    setErrorMessage(error);
  }, [value, error, pristine]);

  return (
    <label
      className={classnames(
        styles.input,
        { [styles._error]: !disabled && !readOnly && errorMessage },
        { [styles._required]: required },
        { [styles._readOnly]: readOnly },
        { [styles._disabled]: disabled },
        className
      )}
    >
      {label && <span className={styles.label}>{label}</span>}
      <span className={styles.field}>
        <input
          {...rest}
          type={type}
          name={name}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          onBlur={() => value.length && setPristine(false)}
          onChange={(e) => onChange({ name, value: e.target.value })}
        />
        {!disabled && !readOnly && value && value.length > 0 && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onChange({ name, value: "" })}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.09977 8.00002L12.4386 4.66109C12.7427 4.35698 12.7427 3.86544 12.4386 3.56133C12.1345 3.25723 11.643 3.25723 11.3389 3.56133L8.00004 6.90026L4.66118 3.56133C4.35708 3.25723 3.86555 3.25723 3.56145 3.56133C3.25735 3.86544 3.25735 4.35698 3.56145 4.66109L6.90031 8.00002L3.56145 11.3389C3.25735 11.6431 3.25735 12.1346 3.56145 12.4387C3.71311 12.5904 3.91221 12.6666 4.11131 12.6666C4.31042 12.6666 4.50952 12.5904 4.66118 12.4387L8.00004 9.09977L11.3389 12.4387C11.4906 12.5904 11.6897 12.6666 11.8888 12.6666C12.0879 12.6666 12.287 12.5904 12.4386 12.4387C12.7427 12.1346 12.7427 11.6431 12.4386 11.3389L9.09977 8.00002Z"
              fill="#87879B"
            />
          </svg>
        )}
        {!disabled && !readOnly && errorMessage && (
          <span className={styles.error}>{errorMessage}</span>
        )}
      </span>
    </label>
  );
};

export default Input;
