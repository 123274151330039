import React from "react";
import classnames from "classnames";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { humanize } from "@remedylogic/rai-canvas-craft";
import features from "../../features.json";
import styles from "./index.module.scss";

const Features = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.features}>
      {features["pathologies"] && features["pathologies"].length && (
        <div className={styles.box}>
          <h3>{t("components.features.spinal-pathologies")}</h3>
          <table>
            <tbody>
              {features["pathologies"]?.map((f, key) => (
                <tr key={key}>
                  <td>
                    <div className={styles.name} data-count={key + 1}>
                      {humanize(t(`components.MRIViewer.everything.${f.name}`))}
                    </div>
                    {f.description && (
                      <div className={styles.description}>
                        {f.description
                          .map((d) =>
                            humanize(t(`components.MRIViewer.everything.${d}`))
                          )
                          .join(", ")}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {features["objects"] && features["objects"].length && (
        <div className={styles.box}>
          <h3>{t("components.features.anatomical-objects")}</h3>
          <table>
            <tbody>
              {features["objects"]?.map((f, key) => (
                <tr key={key}>
                  <td>
                    <div className={styles.name} data-count={key + 1}>
                      {humanize(t(`components.MRIViewer.everything.${f.name}`))}
                    </div>
                    {f.description && (
                      <div className={styles.description}>
                        {f.description
                          .map((d) =>
                            humanize(t(`components.MRIViewer.everything.${d}`))
                          )
                          .join(", ")}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {features["measurents"] && features["measurents"].length && (
        <div className={styles.box}>
          <h3>{t("components.features.measurements")}</h3>
          <table>
            <tbody>
              {features["measurents"]?.map((f, key) => (
                <tr key={key}>
                  <td>
                    <div className={styles.name} data-count={key + 1}>
                      {humanize(t(`components.MRIViewer.everything.${f.name}`))}
                    </div>
                    {f.description && (
                      <div className={styles.description}>
                        {f.description
                          .map((d) =>
                            humanize(t(`components.MRIViewer.everything.${d}`))
                          )
                          .join(", ")}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default Features;
