import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./app";
import { history, persistor, store } from "app/Store";
import { ConnectedRouter } from "connected-react-router/immutable";
import { PersistGate } from "redux-persist/integration/react";

import ErrorBoundary from "components/Errors/Boundary";
import ScrollToTop from "components/ScrollToTop";

import "./i18n";
import "./styles.scss";

import pkg from "../package.json";

console.info(`${pkg.description} version: ${pkg.version}`);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <ScrollToTop />
            <App />
          </Router>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.querySelector("#root")
);
