import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const StatusBadge = ({
  status,
  message,
  className,
}: {
  status: string;
  message: string;
  className?: any;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames(
        styles.statusBadge,
        { [styles._hasPop]: message },
        styles[`_${status}`],
        className
      )}
    >
      {t(`pages.cases.status.${status}`, { defaultValue: status })}
      {message && <div className={styles.pop}>{message}</div>}
    </div>
  );
};

export default StatusBadge;
