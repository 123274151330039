import React, { FC } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

type Props = { folded: boolean };

const Sidebar: FC<Props> = ({ folded, children }) => (
  <div
    className={classnames(styles.sidebar, {
      [styles._folded]: folded,
    })}
    style={{ maxWidth: "225px" }}
  >
    {children}
  </div>
);

export default Sidebar;
