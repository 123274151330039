import React, { useEffect, useRef } from "react";
import {
  humanize,
  simplifyPolygon,
  drawText,
  drawLabel,
  drawLine,
  drawPolygon,
  groupLines,
  getBoundingRectangle,
  getRectangleAroundPoint,
  getPolygonPoints,
  getSegmentationPalette,
  getClippingLinePalette,
  getAngleLabelPosition,
} from "@remedylogic/rai-canvas-craft";
import styles from "./index.module.scss";

const Figure = ({
  figure,
  viewMode,
  clippingLines,
  segmentationOpacity,
  clippingLinesOpacity,
  measurementsVisibility,
  configuration,
}: {
  figure: any;
  viewMode?: string;
  clippingLines?: any;
  segmentationOpacity: number;
  clippingLinesOpacity: number;
  measurementsVisibility: boolean;
  configuration: any;
}) => {
  const canvasRef = useRef(null);
  const H = 1280;
  const scale = H / figure.size[1];
  const ratio = figure.size[1] / figure.size[0];
  useEffect(
    () =>
      (() => {
        const ctx = canvasRef.current.getContext("2d");
        const safe = [
          ...(figure.texts ?? []).map((item: any) =>
            getRectangleAroundPoint(
              [item.center.x, item.center.y],
              ctx.measureText(humanize(item.text)).width,
              ctx.measureText(item.text).actualBoundingBoxAscent +
                ctx.measureText(item.text).actualBoundingBoxDescent,
              scale
            )
          ),
        ];
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.globalAlpha = configuration.MRI_VIEWER_CONFIG.figureDim;
        ctx.drawImage(
          figure.image,
          0,
          0,
          figure.size[0] * scale,
          figure.size[1] * scale
        );
        ctx.globalAlpha = 1;
        ctx.font = configuration.MRI_VIEWER_CONFIG.fontFamily;
        ctx.fillStyle = configuration.MRI_VIEWER_CONFIG.textColor;
        const texts = figure.texts && figure.texts.length ? figure.texts : [];
        viewMode !== "original" &&
          texts.forEach((item: any) =>
            drawText(
              ctx,
              humanize(item.text),
              item.center.x * scale,
              item.center.y * scale,
              configuration.MRI_VIEWER_CONFIG
            )
          );
        if (
          clippingLines &&
          clippingLines.length &&
          figure.projection === "sag"
        ) {
          clippingLines.forEach((clippingLine: any, key: number) => {
            clippingLine.bodyPart === figure.bodyPart &&
              drawLine(
                ctx,
                {
                  x: clippingLine.clippingLine[0][0],
                  y: clippingLine.clippingLine[0][1],
                },
                {
                  x: clippingLine.clippingLine[1][0],
                  y: clippingLine.clippingLine[1][1],
                },
                {
                  ...configuration.MRI_VIEWER_CONFIG,
                  lineWidth: configuration.MRI_VIEWER_CONFIG.clippingLineWidth,
                  lineColor: getClippingLinePalette(clippingLinesOpacity / 100)[
                    key
                  ],
                },
                scale,
                false
              );
          });
        }
        const angles =
          figure.angleMeasurements && figure.angleMeasurements.length
            ? figure.angleMeasurements
            : [];
        measurementsVisibility &&
          viewMode !== "original" &&
          angles
            .filter(
              (angle: any) => (viewMode === "abnormal") === angle.isAbnormal
            )
            .forEach((angle: any) => {
              const { x, y } = getAngleLabelPosition(
                angle.vertex,
                angle.edgePoints,
                25,
                figure.size[0],
                figure.size[1]
              );
              const angleText = humanize(`∠${Math.round(angle.value)}°`);
              safe.push(
                getRectangleAroundPoint(
                  [x, y],
                  ctx.measureText(angleText).width,
                  ctx.measureText(angleText).actualBoundingBoxAscent +
                    ctx.measureText(angleText).actualBoundingBoxDescent,
                  scale
                )
              );
              drawText(ctx, angleText, x * scale, y * scale, {
                ...configuration.MRI_VIEWER_CONFIG,
                textColor: configuration.MRI_VIEWER_CONFIG.angleLineColor,
              });
              angle.edgePoints?.forEach((edge: any) => {
                drawLine(
                  ctx,
                  { x: edge.x, y: edge.y },
                  { x: angle.vertex.x, y: angle.vertex.y },
                  {
                    ...configuration.MRI_VIEWER_CONFIG,
                    lineWidth: configuration.MRI_VIEWER_CONFIG.angleLineWidth,
                    lineColor: configuration.MRI_VIEWER_CONFIG.angleLineColor,
                  },
                  scale,
                  false
                );
              });
            });
        if (viewMode !== "original" && figure.annotationsPolygons) {
          const cfg = { ...configuration.MRI_VIEWER_CONFIG };
          Object.keys(figure.annotationsPolygons).forEach((mode: any) => {
            if (viewMode === "abnormal" && mode !== "abnormal") return null;
            Object.keys(figure.annotationsPolygons[mode]).forEach(
              (type: any) => {
                cfg.polygonLineColor = getSegmentationPalette(
                  segmentationOpacity / 100
                )[type];
                figure.annotationsPolygons[mode][type].forEach(
                  (polygon: any) => {
                    const polygonSimplified = simplifyPolygon(
                      polygon,
                      configuration.MRI_VIEWER_CONFIG.polygonTolerance
                    );
                    drawPolygon(ctx, polygonSimplified, cfg, scale);
                  }
                );
              }
            );
          });
        }
        const areas = figure.areaMeasurements
          ? figure.areaMeasurements.filter((item: any) =>
              viewMode === "abnormal" ? item.isAbnormal : true
            )
          : [];
        measurementsVisibility &&
          viewMode !== "original" &&
          areas.forEach((item: any, key: number) => {
            if (
              !figure.areaMeasurementsPolygons[item.objectName] ||
              !figure.areaMeasurementsPolygons[item.objectName][
                item.objectIndex
              ]
            )
              return null;
            const cfg = {
              ...configuration.MRI_VIEWER_CONFIG,
              ...(item.isAbnormal && {
                lineWidth: 2, // todo remove this
                angleLineWidth: 2, // todo remove this
                triangleSize: 10, // todo remove this
                polygonLineColor: "rgba(255, 0, 0, 0.75)",
                indicatingLineMiddleColor: "rgba(255, 0, 0, 0.5)",
                labelBackgroundColor: "rgba(255, 0, 0, 0.5)",
                labelBorderColor: "#ff7979",
              }),
            };
            const polygon =
              figure.areaMeasurementsPolygons &&
              Object.keys(figure.areaMeasurementsPolygons).length
                ? simplifyPolygon(
                    figure.areaMeasurementsPolygons?.[item.objectName]?.[
                      item.objectIndex
                    ] ?? [],
                    configuration.MRI_VIEWER_CONFIG.polygonTolerance
                  )
                : null;
            safe.push(getBoundingRectangle(polygon, scale));
            polygon && drawPolygon(ctx, polygon, cfg, scale);
            const farthestPoint = getPolygonPoints(
              areas.map((item: any) =>
                figure.areaMeasurementsPolygons &&
                Object.keys(figure.areaMeasurementsPolygons).length
                  ? simplifyPolygon(
                      figure.areaMeasurementsPolygons?.[item.objectName]?.[
                        item.objectIndex
                      ] ?? [],
                      configuration.MRI_VIEWER_CONFIG.polygonTolerance
                    )
                  : []
              )
            )[key];
            if (item.isAbnormal || figure.projection === "sag") {
              safe.push(
                drawLabel(
                  ctx,
                  canvasRef,
                  true,
                  farthestPoint[0] * scale,
                  farthestPoint[1] * scale,
                  `${parseFloat(item.value.toFixed(1))} mm²`,
                  cfg,
                  scale,
                  safe
                ).labelRect
              );
            }
          });
        const lines = figure.linearMeasurements
          ? groupLines(
              figure.linearMeasurements.filter((item: any) =>
                viewMode === "abnormal" ? item.isAbnormal : true
              )
            ).flat()
          : [];
        measurementsVisibility &&
          viewMode !== "original" &&
          lines.forEach((item: any) => {
            const cfg = { ...configuration.MRI_VIEWER_CONFIG };
            if (item.isAbnormal) {
              cfg.lineWidth = 2; // todo remove this
              cfg.triangleSize = 10; // todo remove this
              cfg.lineColor = "#ff0000";
              cfg.triangleColor = "#ff0000";
              cfg.indicatingLineMiddleColor = "rgba(255, 0, 0, 0.5)";
              cfg.labelBackgroundColor = "rgba(255, 0, 0, 0.5)";
              cfg.labelBorderColor = "#ff7979";
            }
            const { centerX, centerY, lineRect } = drawLine(
              ctx,
              item.start,
              item.end,
              cfg,
              scale
            );
            if (
              item.isAbnormal ||
              (figure.projection !== "sag" && item.name.includes("aort"))
            ) {
              const { labelRect } = drawLabel(
                ctx,
                canvasRef,
                true,
                centerX * scale,
                centerY * scale,
                `${parseFloat(item.value.toFixed(1))} mm`,
                cfg,
                scale,
                safe
              );
              safe.push(...[lineRect, labelRect]);
            } else {
              safe.push(...[lineRect]);
            }
          });
      })(),
    [figure, clippingLines, measurementsVisibility]
  );

  return (
    <canvas
      className={styles.figure}
      ref={canvasRef}
      width={H / ratio}
      height={H}
    />
  );
};

export default Figure;
