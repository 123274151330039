import { fromJS } from "immutable";

import {
  CLEAR_UPLOAD,
  REMOVE_FILE_REQUEST,
  SET_FILES_REQUEST,
  SET_GLOBAL_LOADING,
  SET_KEYS_REQUEST,
  SET_PROCESS_REQUEST,
  SET_PROGRESS_REQUEST,
  SET_STATUS_REQUEST,
} from "./constants";

const initialState = fromJS({
  files: [],
  progress: {},
  status: {},
  queue: {},
  global: {
    loading: false,
    uploaded: 0,
    totalFiles: 0,
  },
}) as any;

const uploadReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_FILES_REQUEST: {
      return state.updateIn(["files"], (data: any) => {
        return [...data, ...payload];
      });
    }
    case REMOVE_FILE_REQUEST:
      return state.updateIn(["files"], (data: any) => {
        const list = [...data];
        const indexId = Array.from(list).findIndex(
          (file: any) => file?.hash === payload
        );

        if (indexId > -1) {
          list.splice(indexId, 1);
        }
        return [...list];
      });
    case SET_PROGRESS_REQUEST:
      return state.setIn(["progress", payload.hash], payload.percentage);

    case SET_STATUS_REQUEST:
      return state.setIn(["status", payload.hash], payload.status);

    case SET_KEYS_REQUEST:
      return state.updateIn(["files"], (data: any) => {
        const list = [...data];
        const indexId = Array.from(list).findIndex(
          (file: any) => file?.hash === payload.hash
        );
        list[indexId].fileKey = payload.fileKey;
        list[indexId].mimeType = payload.mimeType;
        return [...list];
      });
    case SET_GLOBAL_LOADING:
      return state.setIn(["global", "loading"], payload);
    case SET_PROCESS_REQUEST:
      return state
        .setIn(["global", "uploaded"], payload.uploaded)
        .setIn(["global", "totalFiles"], payload.totalFiles)
        .setIn(["global", "remoteFolder"], payload.remoteFolder)
        .setIn(["global", "uploadPackage"], payload.uploadPackage);
    case CLEAR_UPLOAD:
      return initialState;
    default:
      return state;
  }
};

export default uploadReducer;
